import { lazy } from 'react';

import MainLyout from '../components/layout/MainLyout';
import ProtectRoute from '../components/common/ProtectRoute';
import ApprovalCategories from '../components/Pages/company/approvalCategories/ApprovalCategories';
import CreateApprovalCategories from '../components/Pages/company/approvalCategories/CreateApprovalCategories';
import ApprovalCategoriesChild from '../components/Pages/company/approvalCategories/ApprovalCategoriesChild';
import Playground from '../components/Pages/Playground';
import { key } from '../config';

const AuthPage = lazy(() => import('../components/Auth/Auth'));

const ViewMaterialPage = lazy(() =>
  import('../components/Pages/company/Material/ViewMaterial')
);
const InspectionPlanPage = lazy(() =>
  import('../components/Pages/company/InspectionPlan/InspectionPlan')
);

const ViewCompanyPage = lazy(() =>
  import('../components/Pages/saas/CompanyManagement/ViewComapny')
);

const ViewInspectionLotPage = lazy(() =>
  import('../components/Pages/company/InspectionLot/ViewInspectionLot')
);

const ViewWorkStationPage = lazy(() =>
  import('../components/Pages/company/Workstation/ViewWorkStation')
);

const SensorTypePage = lazy(() =>
  import('../components/Pages/company/Sensors/SensorType/SensorType')
);

const CreateSensorTypePage = lazy(() =>
  import('../components/Pages/company/Sensors/SensorType/CreateSensorType')
);

const ViewSensorTypePage = lazy(() =>
  import('../components/Pages/company/Sensors/SensorType/ViewSensorType')
);

const ViewSensorPage = lazy(() =>
  import('../components/Pages/company/Sensors/ViewSensor')
);

const DashboardPage = lazy(() => import('../components/Pages/Playground'));

const NoMatchPage = lazy(() => import('../components/common/Error404'));
const ForbPage = lazy(() => import('../components/common/Error403'));
const UnAuthPage = lazy(() => import('../components/common/Error401'));

const ForgotPasswordPage = lazy(() =>
  import('../components/Auth/ForgotPassword')
);
const CreateNewPass = lazy(() => import('../components/Auth/CreateNewPass'));
const RegisterPage = lazy(() => import('../components/Auth/Register'));
const CompanyManagmentPage = lazy(() =>
  import('../components/Pages/saas/CompanyManagement/CompanyManagment')
);
const CreateComapnyPage = lazy(() =>
  import('../components/Pages/saas/CompanyManagement/CreateComapny')
);
const CreateSubscriptionPage = lazy(() =>
  import('../components/Pages/saas/subscription/CreateSubscription')
);
const EditSubscriptionPage = lazy(() =>
  import('../components/Pages/saas/subscription/EditSubscription')
);
const AdminLogPage = lazy(() =>
  import('../components/Pages/company/adminLogs/AdminLog')
);

const ManageSubscriptionPage = lazy(() =>
  import('../components/Pages/saas/subscription/ManageSubscription')
);
const TransactionPage = lazy(() =>
  import('../components/Pages/saas/transaction/Transaction')
);
const ConsentPagePage = lazy(() =>
  import('../components/Pages/saas/registrationConsent/Consent')
);
const AccountInfoPage = lazy(() =>
  import('../components/Pages/company/AccountInfo')
);
const WorkistationPage = lazy(() =>
  import('../components/Pages/company/Workstation/Workistation')
);
const CreateWorkstationPage = lazy(() =>
  import('../components/Pages/company/Workstation/CreateWorkstation')
);
const PlantPage = lazy(() => import('../components/Pages/company/Plant/Plant'));

const GraphsPage = lazy(() =>
  import('../components/Pages/company/reports/graphs')
);

const CreatePlantPage = lazy(() =>
  import('../components/Pages/company/Plant/CreatePlant')
);
const UpdatePlantPage = lazy(() =>
  import('../components/Pages/company/Plant/UpdatePlant')
);

const UserPage = lazy(() => import('../components/Pages/company/User/User'));
const CreateUserPage = lazy(() =>
  import('../components/Pages/company/User/CreateUser')
);
const ViewUserPage = lazy(() =>
  import('../components/Pages/company/User/ViewUser')
);
const MaterialNoConfigPage = lazy(() =>
  import('../components/Pages/company/MaterialNoConfig/MaterialNoConfig')
);
const NoConfigCreatePage = lazy(() =>
  import('../components/Pages/company/MaterialNoConfig/NoConfigCreate')
);
const NoConfigUpdatePage = lazy(() =>
  import('../components/Pages/company/MaterialNoConfig/NoConfigUpdate')
);
const AdminRoleManagment = lazy(() =>
  import('../components/Pages/saas/AdminRoleManagment/AdminRoleManagment')
);
const CreateAdminRole = lazy(() =>
  import('../components/Pages/saas/AdminRoleManagment/CreateAdminRole')
);

const MaterialPage = lazy(() =>
  import('../components/Pages/company/Material/Material')
);
const CreateMaterialPage = lazy(() =>
  import('../components/Pages/company/Material/CreateMaterial')
);
const RejectionReasonPage = lazy(() =>
  import('../components/Pages/company/Rejection/RejectionReason')
);
const CreateRejectionReasonPage = lazy(() =>
  import('../components/Pages/company/Rejection/CreateRejectionReason')
);
const CreateMeasurementUnitsPage = lazy(() =>
  import('../components/Pages/company/MeasurementUnits/CreateMeasurementUnits')
);
const MeasurementUnitsPage = lazy(() =>
  import('../components/Pages/company/MeasurementUnits/MeasurementUnits')
);
const CreateInspectionPlanPage = lazy(() =>
  import('../components/Pages/company/InspectionPlan/CreateInspectionPlan')
);
const SensorConfigurationsPage = lazy(() =>
  import('../components/Pages/company/Sensors/Sensors')
);
const CreateSensorPage = lazy(() =>
  import('../components/Pages/company/Sensors/CreateSensor')
);
const InspectionPlanLotPage = lazy(() =>
  import('../components/Pages/company/InspectionLot/InspectionLot')
);
const CreateInspectionPlanLotPage = lazy(() =>
  import('../components/Pages/company/InspectionLot/CreateInspectionLot')
);
const BuildAreaPage = lazy(() =>
  import('../components/Pages/company/BuildArea/BuildArea')
);
const ViewBuildAreaPage = lazy(() =>
  import('../components/Pages/company/BuildArea/ViewBuildArea')
);
const UpdateMeasurementUnitPage = lazy(() =>
  import('../components/Pages/company/MeasurementUnits/UpdateMeasurementUnit')
);
const ViewRejectionReasonPage = lazy(() =>
  import('../components/Pages/company/Rejection/ViewRejectionReasonChild')
);

const ViewRejectionReason = lazy(() =>
  import('../components/Pages/company/Rejection/ViewRejectionReason')
);

const RejectionReasonChildPage = lazy(() =>
  import('../components/Pages/company/Rejection/RejectionReasonChilds')
);

const GroupCreatePage = lazy(() =>
  import('../components/WorkFlow/groupCreate')
);

const GroupListingPage = lazy(() =>
  import('../components/WorkFlow/GroupListing')
);

// const EditGroupPage = lazy(() =>
//   import("../components/WorkFlow/editGroup")
// );
const EditGroupPage = lazy(() => import('../components/WorkFlow/EditGroup'));
const FormBuilderPage = lazy(() =>
  import('../components/Pages/FormBank/AllForms')
);
const ShowFormDetailPage = lazy(() =>
  import('../components/Pages/FormBank/ShowFormDetail')
);
const FixedFormDetailPage = lazy(() =>
  import('../components/Pages/FormBank/FixedFormTrial')
);
const UpdateFormPage = lazy(() =>
  import('../components/Pages/FormBank/UpdateForm')
);
const CreatGroupPage = lazy(() => import('../components/WorkFlow/CreatGroup'));

const EditFormBuilderPage = lazy(() =>
  import('../components/Pages/FormBank/EditFormBuilder')
);
const CreateFormPage = lazy(() =>
  import('../components/Pages/FormBank/FormCreator')
);
const FormInitiatorPage = lazy(() =>
  import('../components/Pages/company/FutureUseForms/FormInitiator')
);
const CustomFormCrudPage = lazy(() =>
  import('../components/Pages/company/FutureUseForms/CustomFormCrud')
);
const MainInspectionPlanTreePage = lazy(() =>
  import('../components/Pages/company/InspectionPlan/MainInspectionPlanTree')
);
// const StepPage = lazy(() =>
//   import("../components/Pages/company/InspectionPlan/forms/Step")
// );
const FixedFieldsPage = lazy(() =>
  import('../components/Pages/company/FutureUseForms/FixedFields')
);
const ViewFixedFormPage = lazy(() =>
  import('../components/Pages/company/FutureUseForms/ViewFixedForm')
);
const ViewCustomFormPage = lazy(() =>
  import('../components/Pages/company/FutureUseForms/ViewCustomForm')
);
const CompReg = lazy(() => import('../site/pages/modal/companyReg.jsx'));
const PaymentMethodPage = lazy(() =>
  import('../components/Pages/company/FutureUseForms/PaymentMethod')
);
const PlanSubscriptionPage = lazy(() =>
  import('../components/Pages/company/FutureUseForms/PlanSubscription')
);
const PlanUpgradePage = lazy(() =>
  import('../components/Pages/company/FutureUseForms/Plan-upgrade')
);
const AssignAdminPermissionsPage = lazy(() =>
  import(
    '../components/Pages/saas/AdminRoleManagment/Permissions/AssignAdminPermissions'
  )
);
const ViewAdminRole = lazy(() =>
  import('../components/Pages/saas/AdminRoleManagment/ViewAdminRole')
);
const FMEAPage = lazy(() =>
  import('../components/Pages/company/FutureUseForms/FMEA')
);
const FemaReportPage = lazy(() =>
  import('../components/Pages/company/reports/Fema')
);
const GroupPage = lazy(() => import('../components/Pages/company/Group/Group'));
const CreateGroupsPage = lazy(() =>
  import('../components/Pages/company/Group/CreateGroups')
);
const ViewGroupPage = lazy(() =>
  import('../components/Pages/company/Group/ViewGroup')
);

const FishbonePage = lazy(() =>
  import('../components/Pages/company/reports/fishbone')
);
const FiveWhy = lazy(() => import('../components/Pages/company/reports/5Y'));
const InspectionLotReport = lazy(() =>
  import('../components/Pages/company/reports/InspectionLotReport')
);

const GeneralFormListPage = lazy(() =>
  import('../components/Pages/company/reports/generalForm/GeneralFormList')
);

const GeneralFormFilledByPage = lazy(() =>
  import('../components/Pages/company/reports/generalForm/GeneralFormFilledBy')
);
const CompanyRole = lazy(() =>
  import('../components/Pages/company/RoleManagment/CompanyRoleManagment')
);
const ViewCompanyRole = lazy(() =>
  import('../components/Pages/company/RoleManagment/ViewCompanyRole')
);
const ViewCompanyPermissions = lazy(() =>
  import(
    '../components/Pages/company/RoleManagment/Permissions/AssignCompanyPermissions'
  )
);
const CreateCompanyRole = lazy(() =>
  import('../components/Pages/company/RoleManagment/CreateCompanyRole')
);
const StepReport = lazy(() =>
  import('../components/Pages/company/reports/StepsReport')
);
const QcReport = lazy(() =>
  import('../components/Pages/company/reports/QcPointReport')
);
const CompanyPlans = lazy(() =>
  import('../components/Pages/saas/CompanyPlans/CompanyPlans')
);

let PublicRoutes = [
  {
    path: '/login',
    element: <AuthPage />,
  },
  {
    path: '/compReg',
    element: <CompReg />,
  },
  { path: '/regPage', element: <RegisterPage /> },
  {
    path: '/forgot',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/newPass',
    element: <CreateNewPass />,
  },
  // {
  //   path: "/plan-subscription",
  //   element: (
  //     // <ProtectRoute>
  //     <PlanSubscriptionPage />
  //     // </ProtectRoute>
  //   ),
  // },
  // { path: "*", element: <NoMatchPage /> },
];

let PrivateRoutes = [
  {
    path: '/',
    element: <MainLyout />,
    children: [
      {
        index: true,
        element: (
          // <ProtectRoute per={key.permissions.Dashboard.VIEW}>
          <GraphsPage />
          // </ProtectRoute>
        ),
      },

      // saas starts
      {
        path: '/company',
        element: <CompanyManagmentPage />,
      },
      {
        path: '/company/create',
        element: <CreateComapnyPage />,
      },
      {
        path: '/company/:Id',
        element: <ViewCompanyPage />,
      },
      {
        path: '/subscription',

        element: (
          <ProtectRoute per={'Plan-View'}>
            <ManageSubscriptionPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/subscription/create',
        element: (
          // <ProtectRoute>
          <CreateSubscriptionPage />
          // </ProtectRoute>
        ),
      },
      {
        path: '/edit-subscription/:id',
        element: (
          // <ProtectRoute>
          <EditSubscriptionPage />
          // </ProtectRoute>
        ),
      },
      {
        path: '/transaction',
        element: <TransactionPage />,
      },
      {
        path: '/consent',
        element: <ConsentPagePage />,
      },

      {
        path: '/admin-log',
        element: (
          // <ProtectRoute>
          <AdminLogPage />
          // </ProtectRoute>
        ),
      },
      {
        path: '/adminRoles',
        element: <AdminRoleManagment />,
      },
      {
        path: '/adminRoles/create',
        element: <CreateAdminRole />,
      },
      {
        path: '/adminRoles/:roleId',
        element: <ViewAdminRole />,
      },
      {
        path: '/companyRoles',
        element: <CompanyRole />,
      },
      {
        path: '/companyRoles/:roleId',
        element: <ViewCompanyRole />,
      },
      {
        path: '/companyRoles/create',
        element: <CreateCompanyRole />,
      },
      {
        path: '/view-company-permissions/:roleId',

        element: (
          // <ProtectRoute>
          <ViewCompanyPermissions />
          // </ProtectRoute>
        ),
      },
      {
        path: '/companyPlans',
        element: <CompanyPlans />,
      },
      {
        path: '/plan-subscription',
        element: (
          // <ProtectRoute>
          <PlanSubscriptionPage />
          // </ProtectRoute>
        ),
      },
      {
        path: '/plan-upgrade',
        element: (
          // <ProtectRoute>
          <PlanUpgradePage />
          // </ProtectRoute>
        ),
      },
      {
        path: '/payment-method',
        element: (
          // <ProtectRoute>
          <PaymentMethodPage />
          // </ProtectRoute>
        ),
      },

      // ************************************************************ saas ends here

      // com
      {
        path: '/editGroup/:Id',
        element: (
          <ProtectRoute per={key.permissions.ApprovalGroups.VIEW}>
            <EditGroupPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/group/create',
        element: (
          <ProtectRoute per={key.permissions.ApprovalGroups.CREATE}>
            <CreatGroupPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/form-builder',
        element: (
          <ProtectRoute per={key.permissions.GeneralWorkflow.VIEW}>
            <FormBuilderPage />
          </ProtectRoute>
        ),
      },
      {
        // path: "/creatForm",
        path: '/form-builder/create',
        element: (
          <ProtectRoute per="Forms-Create">
            <CreateFormPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/updateForm',
        element: (
          <ProtectRoute per="Forms-Update">
            <UpdateFormPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/userEnableForm',
        element: (
          <ProtectRoute per={key.permissions.ApprovalTrails.TRAIL}>
            <ShowFormDetailPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/FixedFormDetail',
        element: (
          <ProtectRoute per={key.permissions.ApprovalTrails.TRAILS_VIEW}>
            <FixedFormDetailPage />
          </ProtectRoute>
        ),
      },

      {
        path: '/account',
        element: (
          // <ProtectRoute>
          <AccountInfoPage />
          // </ProtectRoute>
        ),
      },
      {
        path: '/station',
        element: (
          <ProtectRoute per={key.permissions.WorkStation.VIEW}>
            <WorkistationPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/station/create',
        element: (
          <ProtectRoute per={key.permissions.WorkStation.CREATE}>
            <CreateWorkstationPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/station/:Id',
        element: (
          <ProtectRoute per={key.permissions.WorkStation.VIEW}>
            <ViewWorkStationPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/plant',
        element: (
          <ProtectRoute per={key.permissions.Plants.VIEW}>
            <PlantPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/plant/create',
        element: (
          <ProtectRoute per={key.permissions.Plants.CREATE}>
            <CreatePlantPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/view-plant/edit/:id',
        element: (
          <ProtectRoute per={key.permissions.Plants.VIEW}>
            <UpdatePlantPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/user',
        element: (
          <ProtectRoute per={key.permissions.User.VIEW}>
            <UserPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/user/create',
        element: (
          <ProtectRoute per={key.permissions.User.CREATE}>
            <CreateUserPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/user/edit/:id',
        element: (
          <ProtectRoute per={key.permissions.User.EDIT}>
            <ViewUserPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/material-no-config',
        element: (
          <ProtectRoute per={key.permissions.MaterialNoConfig.VIEW}>
            <MaterialNoConfigPage />
          </ProtectRoute>
        ),
      },

      {
        path: '/material',
        element: (
          <ProtectRoute per={key.permissions.MaterialMaster.VIEW}>
            <MaterialPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/material/create',
        element: (
          <ProtectRoute per={key.permissions.MaterialMaster.CREATE}>
            <CreateMaterialPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/material/:id',
        element: (
          <ProtectRoute per={key.permissions.MaterialMaster.VIEW}>
            <ViewMaterialPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/inspection-lot',
        element: (
          <ProtectRoute per={key.permissions.InspectionLot.VIEW}>
            <InspectionPlanLotPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/inspection-lot/create',
        element: (
          <ProtectRoute per={key.permissions.InspectionLot.CREATE}>
            {' '}
            <CreateInspectionPlanLotPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/inspection-lot/:id',
        element: (
          <ProtectRoute per={key.permissions.InspectionLot.VIEW}>
            <ViewInspectionLotPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/measurement-units',
        element: (
          <ProtectRoute per={key.permissions.MeasurementUnits.VIEW}>
            <MeasurementUnitsPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/measurement-units/create',
        element: (
          <ProtectRoute per={key.permissions.MeasurementUnits.CREATE}>
            <CreateMeasurementUnitsPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/measurement-unit/:id',
        element: (
          <ProtectRoute per={key.permissions.MeasurementUnits.VIEW}>
            <UpdateMeasurementUnitPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/inspection-plan',
        element: (
          <ProtectRoute per={key.permissions.InspectionPlan.VIEW}>
            <InspectionPlanPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/inspection-plan/view/:id',
        element: (
          <ProtectRoute per={key.permissions.InspectionPlan.VIEW}>
            <MainInspectionPlanTreePage />
          </ProtectRoute>
        ),
      },
      {
        path: '/inspection-plan/create',
        element: (
          <ProtectRoute per={key.permissions.InspectionPlan.CREATE}>
            <CreateInspectionPlanPage />
          </ProtectRoute>
        ),
      },

      // {
      //   path: "/inspection-plan/step",
      //   element: (
      //     <ProtectRoute>
      //       <StepPage />
      //     </ProtectRoute>
      //   ),
      // },
      {
        path: '/sensor',
        element: (
          <ProtectRoute per={key.permissions.Sensors.VIEW}>
            <SensorConfigurationsPage />
          </ProtectRoute>
        ),
      },

      {
        path: '/sensor/create',
        element: (
          <ProtectRoute per={key.permissions.Sensors.CREATE}>
            <CreateSensorPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/sensor/:id',
        element: (
          <ProtectRoute per={key.permissions.Sensors.VIEW}>
            <ViewSensorPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/sensor-type',
        element: (
          <ProtectRoute per={key.permissions.SensorType.VIEW}>
            <SensorTypePage />
          </ProtectRoute>
        ),
      },
      {
        path: '/create-sensor-type',
        element: (
          <ProtectRoute per={key.permissions.SensorType.CREATE}>
            <CreateSensorTypePage />
          </ProtectRoute>
        ),
      },
      {
        path: 'sensor-type/:id',
        element: (
          <ProtectRoute per={key.permissions.SensorType.VIEW}>
            {' '}
            <ViewSensorTypePage />
          </ProtectRoute>
        ),
      },
      {
        path: '/form-initiator',
        element: (
          // <ProtectRoute>
          <FormInitiatorPage />
          // </ProtectRoute>
        ),
      },
      {
        path: '/custom-form',
        element: (
          // <ProtectRoute>
          <CustomFormCrudPage />
          // </ProtectRoute>
        ),
      },
      {
        path: '/fixed-fields',
        element: (
          <ProtectRoute per={key.permissions.ApprovalForm.CREATE}>
            <FixedFieldsPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/fixed-form/view',
        element: (
          // <ProtectRoute>
          <ViewFixedFormPage />
          // </ProtectRoute>
        ),
      },
      {
        path: '/custom-form/view',
        element: (
          // <ProtectRoute>
          <ViewCustomFormPage />
          // </ProtectRoute>
        ),
      },

      // {
      //   path: "/groups",
      //   element: (
      //     <ProtectRoute per={key.permissions.Groups.VIEW}>
      //       <GroupPage />
      //     </ProtectRoute>
      //   ),
      // },
      // {
      //   path: "/groups/create",
      //   element: (
      //     <ProtectRoute per={key.permissions.Groups.CREATE}>
      //       <CreateGroupsPage />
      //     </ProtectRoute>
      //   ),
      // },
      // {
      //   path: "/groups/:Id",
      //   element: (
      //     <ProtectRoute per={key.permissions.Groups.VIEW}>
      //       <ViewGroupPage />
      //     </ProtectRoute>
      //   ),
      // },
      {
        path: '/FMEA',
        element: (
          <ProtectRoute per={key.permissions.FMEA.VIEW}>
            <FMEAPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/report/fema/:id',
        element: (
          // <ProtectRoute per={key.permissions.FMEAReport.VIEW}>
          <FemaReportPage />
          // </ProtectRoute>
        ),
      },

      {
        path: '/report/fishbone',
        element: (
          <ProtectRoute per="Fishbone-View">
            <FishbonePage />
          </ProtectRoute>
        ),
      },
      {
        path: '/report/5why/:id',
        element: (
          // <ProtectRoute per={key.permissions.Report2.VIEW}>
          <FiveWhy />
          // </ProtectRoute>
        ),
      },
      {
        path: '/build-area',
        element: (
          <ProtectRoute per={key.permissions.BuildArea.VIEW}>
            <BuildAreaPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/build-area/:id',
        element: (
          <ProtectRoute per={key.permissions.BuildArea.VIEW}>
            <ViewBuildAreaPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/inspection-lot-report',
        element: (
          <ProtectRoute per={key.permissions.InspectionLotReport.VIEW}>
            <InspectionLotReport />
          </ProtectRoute>
        ),
      },
      {
        path: '/step-report/:id/:flag',
        element: (
          // <ProtectRoute>
          <StepReport />
          // </ProtectRoute>
        ),
      },
      {
        path: '/qc-report/:id/steps/:stepId',
        element: (
          // <ProtectRoute>
          <QcReport />
          // </ProtectRoute>
        ),
      },
      {
        path: '/material-no-config/create',
        element: (
          <ProtectRoute>
            <NoConfigCreatePage />
          </ProtectRoute>
        ),
      },
      {
        path: '/material-no-config/:id',
        element: (
          <ProtectRoute per={key.permissions.MaterialNoConfig.VIEW}>
            <NoConfigUpdatePage />
          </ProtectRoute>
        ),
      },
      {
        path: '/assign-admin-permissions/:rolId',
        element: (
          <ProtectRoute per={key.permissions.Permissions.VIEW}>
            {' '}
            <AssignAdminPermissionsPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/rejection-reasons',
        element: (
          <ProtectRoute per={key.permissions.RejectionReason.VIEW}>
            <RejectionReasonPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/rejection-reasons/:Id/parentId/:parentId',
        element: (
          // <ProtectRoute>
          <ViewRejectionReasonPage />
          // </ProtectRoute>
        ),
      },
      {
        path: '/rejection-reasons/:Id',
        element: (
          <ProtectRoute per={key.permissions.RejectionReason.VIEW}>
            <ViewRejectionReason />
          </ProtectRoute>
        ),
      },
      {
        path: '/rejection-reasons/child/:parentId',
        element: (
          <ProtectRoute per={key.permissions.RejectionReason.VIEW}>
            <RejectionReasonChildPage />
          </ProtectRoute>
        ),
      },

      {
        path: '/rejection-reasons/create',
        element: (
          <ProtectRoute per={key.permissions.RejectionReason.CREATE}>
            {' '}
            <CreateRejectionReasonPage />
          </ProtectRoute>
        ),
      },

      {
        path: '/approval-categories',
        element: (
          <ProtectRoute per="ApprovalCategories-View">
            <ApprovalCategories />
          </ProtectRoute>
        ),
      },
      {
        path: '/approval-categories/create',
        element: (
          <ProtectRoute per="ApprovalCategories-Create">
            <CreateApprovalCategories />
          </ProtectRoute>
        ),
      },
      {
        path: '/approval-categories/child/:Id',
        element: (
          <ProtectRoute per="ApprovalCategories-Edit">
            <ApprovalCategoriesChild />
          </ProtectRoute>
        ),
      },
      {
        path: '/group/list',
        element: (
          <ProtectRoute per={key.permissions.ApprovalGroups.VIEW}>
            <GroupListingPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/custom-form/view',
        element: (
          // <ProtectRoute>
          <ViewCustomFormPage />
          // </ProtectRoute>
        ),
      },
      {
        path: '/reportsDashboard',
        element: (
          <ProtectRoute per={key.permissions.Dashboard.VIEW}>
            <GraphsPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/report/general-form',
        element: (
          <ProtectRoute per={key.permissions.GeneralForm.VIEW}>
            <GeneralFormListPage />
          </ProtectRoute>
        ),
      },
      {
        path: '/report/general-form/filled/:Id',
        element: (
          // <ProtectRoute>
          <GeneralFormFilledByPage />
          // </ProtectRoute>
        ),
      },
      { path: '/forb', element: <UnAuthPage /> },
      { path: '/unAuth', element: <ForbPage /> },
      { path: '*', element: <NoMatchPage /> },
    ],
  },
];

export { PrivateRoutes, PublicRoutes };
