import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../config';
import { toast } from 'react-toastify';
import { SHOW_ERROR, SHOW_SUCCESS } from '../../utils/toastMessages';
import {
  BUILD_AREA_CREATE,
  BUILD_AREA_DELETE,
  BUILD_AREA_UPDATE,
  ERROR_MESS,
} from '../../constant';

const initialState = {
  areas: [],
  area: {},
  // treeStuff: { currentNode: {}, tree: [] },
  error: null,
  status: null,
  paginationData: {},
};

export const fetchBuildAreas = createAsyncThunk('get/Areas', async (page) => {
  try {
    const response = await API.BuildArea.getAll({
      key: page ? 'page' : '',
      value: page,
    });

    const { data, success } = response.data;
    // //console.log(response.data);
    return { data, success, code: 200, page };
  } catch (error) {
    // // //console.log(error.response.data.message);
    return error.response.data;
  }
});

export const addBuildAreaData = createAsyncThunk(
  'post/Area',
  async (fromData, thunkAPI) => {
    try {
      const response = await API.BuildArea.add(fromData.finalData);
      const { success, data } = response.data;

      if (success) {
        fromData.handleClose();
        thunkAPI.dispatch(fetchOneBuildAreaData(data?.buildMasterId));
        SHOW_SUCCESS(success, 'Build Area added successfully.');
      }

      return {
        data: {},
        success,
        code: 200,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const deleteBuildArea = createAsyncThunk('delete/Area', async (data) => {
  try {
    const response = await API.BuildArea.delete(data);
    // // //console.log(response.data);
    SHOW_SUCCESS(response.data.success, BUILD_AREA_DELETE);

    return data;
  } catch (error) {
    // // //console.log(error.response.data.message);
    const { code, success, message, errors } = error.response.data;
    // console.log("error => ", error.response.data);
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 404) {
      SHOW_ERROR(true, message);
    } else {
      SHOW_ERROR(true, ERROR_MESS);
    }
    return [];
  }
});

export const deleteNode = createAsyncThunk('delete/AreaNode', async (data) => {
  try {
    const response = await API.BuildArea.nodeDelete(data.id);
    // // console.log(response.data);
    if (response.data.success) {
      console.log('id', data.param);
      data.dispatch(fetchOneBuildAreaData(data?.param));
      SHOW_SUCCESS(response.data.success, 'Node deleted successfully');
    }

    return data;
  } catch (error) {
    // // //console.log(error.response.data.message);
    const { code, success, message, errors } = error.response.data;
    // console.log("error => ", error.response.data);
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 404) {
      SHOW_ERROR(true, message);
    } else {
      SHOW_ERROR(true, ERROR_MESS);
    }
    return [];
  }
});

export const fetchOneBuildAreaData = createAsyncThunk(
  'get/Area',
  async (id) => {
    try {
      const response = await API.BuildArea.getOne(id);
      // // // //console.log(response.data);

      return response.data.data;
    } catch (error) {
      // // // //console.log(error.response.data.message);
      return error.response.data;
    }
  }
);

export const addBuildAreaMaster = createAsyncThunk(
  'post/AreaMaster',
  async (formData) => {
    try {
      const response = await API.BuildArea.addMaster(formData.finalData);
      // //console.log(formData);
      const { success, data } = response.data;

      if (success) {
        SHOW_SUCCESS(true, BUILD_AREA_CREATE);
        const PUSH = `/build-area/${data.id}`;
        formData.handleClose();
        formData.navigate(PUSH, { replace: true });
      }

      return {
        success,
        data,
        code: 200,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const updateBuilArea = createAsyncThunk(
  'put/area',
  async (formData, thunkAPI) => {
    try {
      const response = await API.BuildArea.update(
        formData.id,
        formData.finalData
      );

      const { success, data } = response.data;
      // //console.log(data);
      if (success) {
        thunkAPI.dispatch(fetchOneBuildAreaData(data?.buildMasterId));
        SHOW_SUCCESS(success, BUILD_AREA_UPDATE);
      }

      return {
        // data: { onBuildArea: onBuildArea.data.data[0] },
        data: {},
        success,
        code: 200,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

const BuildAreaSlice = createSlice({
  name: 'buildArea',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBuildAreas.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchBuildAreas.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Adding date and reactions
        const { data, success, code, page } = action.payload;

        if (success && code === 200) {
          if (page) {
            state.areas = data.buildAreaMasters;
            delete data.buildAreaMasters;
            state.paginationData = data;
          } else {
            state.areas = data;
          }
        }
      })
      .addCase(fetchBuildAreas.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      // post data reduces
      .addCase(addBuildAreaData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addBuildAreaData.fulfilled, (state, action) => {
        const {
          data: { onBuildArea },
          success,
          code,
        } = action.payload;

        if (success && code === 200) {
          state.status = 'succeeded';
          // //console.log(onBuildArea);
          // state.area = onBuildArea;
        }
      })
      .addCase(addBuildAreaData.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      // get plan data reduces
      .addCase(fetchOneBuildAreaData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchOneBuildAreaData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.area = action.payload[0];
      })
      .addCase(fetchOneBuildAreaData.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      // delete plan data reduces
      .addCase(deleteBuildArea.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteBuildArea.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Adding date and reactions
        state.areas = state.areas.filter((item) => item.id !== action.payload);
        // Add any fetched posts to the array
        // // // //console.log(action.payload);
        // state.areas = action.payload.inspectionareas;
      })
      .addCase(deleteBuildArea.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      //node delete
      .addCase(deleteNode.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteNode.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(deleteNode.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(addBuildAreaMaster.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addBuildAreaMaster.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = 'succeeded';
          state.areas.unshift(data);
        } else {
          state.status = 'failed';
        }
      })
      .addCase(addBuildAreaMaster.rejected, (state, action) => {
        state.status = 'failed';
        // // //console.log(action.error);
        state.error = action.payload;
      })

      .addCase(updateBuilArea.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateBuilArea.fulfilled, (state, action) => {
        const {
          data: { onBuildArea },
          success,
          code,
        } = action.payload;

        if (success && code === 200) {
          state.status = 'succeeded';
          // //console.log(onBuildArea);
          // state.area = onBuildArea;
        } else {
          state.status = 'failed';
        }
      })
      .addCase(updateBuilArea.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      });
  },
});

// export const { updateTree } = inspectionareaslice.actions;

export default BuildAreaSlice.reducer;
