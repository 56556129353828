import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../config';
import { SHOW_ERROR } from '../../utils/toastMessages';
import { ERROR_MESS } from '../../constant';

// actions
const TRANS_LIST = 'history/list';

const initialState = {
  status: '',
  transactionList: [],
};

// action
export const getAllTransactions = createAsyncThunk(TRANS_LIST, async () => {
  try {
    const response = await API.TransactionHistory.list();
    const { data, success } = response.data;
    return { data, success, code: 200 };
  } catch (error) {
    const { code, success, message, errors } = error.response.data;
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 422) {
      SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
    } else {
      SHOW_ERROR(true, ERROR_MESS);
    }
    return {
      success,
      data: {},
      code,
    };
  }
});

const transactionHistorySlice = createSlice({
  name: 'transaction_history',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAllTransactions.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllTransactions.fulfilled, (state, action) => {
        const { code, data, success } = action.payload;
        if (success && code === 200) {
          state.status = 'succeeded';
          state.transactionList = data;
        } else {
          state.status = 'failed';
        }
      })
      .addCase(getAllTransactions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});
// export const {} = transactionHistorySlice.actions;

export default transactionHistorySlice.reducer;
