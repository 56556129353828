// import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const PasswordUpdate = ({ handleClose, passFun, open }) => {
  // const [pass, setPass] = useState({});

  const dispatch = useDispatch();

  const initState = {
    password: '',
    password_confirmation: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
      .required('Password is required'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Password confirmation is required'),
  });

  // useEffect(() => {
  //   dispatch(fetchOneUserData(id));

  //   return () => {};
  // }, [dispatch, id]);

  // const handlePassword = (e) => {
  //   e.preventDefault();
  //   dispatch(passFun(pass));
  //   handleClose();
  // };
  // //console.log(pass);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #69809a',
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box sx={style} className="rounded bg-white">
          <Typography
            className="text-center"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Create New Password
          </Typography>
          <Formik
            initialValues={initState}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(passFun(values));
              handleClose();
              // //console.log(values);
            }}
            className="d-flex justify-content-between align-items-center flex-nowrap flex-column"
          >
            {({ errors, touched, handleBlur }) => (
              <Form className="text-center">
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {/* input field */}
                  <div className="mb-3 row text-left">
                    <label className="col-form-label col-sm-3" htmlFor="name">
                      New Password
                    </label>

                    <div className="col-sm-9 my-auto">
                      <Field
                        onBlur={handleBlur}
                        type="password"
                        className={`form-control ${
                          errors.password && touched.password
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="name"
                        name="password"
                        placeholder="New Password"
                      />
                      <ErrorMessage
                        name="password"
                        component="span"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-3" htmlFor="name">
                      Confirm Password
                    </label>

                    <div className="col-sm-9 my-auto">
                      <Field
                        onBlur={handleBlur}
                        type="password"
                        className={`form-control ${
                          errors.password_confirmation &&
                          touched.password_confirmation
                            ? 'is-invalid'
                            : ''
                        }`}
                        id="name"
                        name="password_confirmation"
                        placeholder="Confirm New Password"
                      />
                      <ErrorMessage
                        name="password_confirmation"
                        component="span"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </Typography>
                <button
                  onClick={handleClose}
                  type="submit"
                  className="btn btn-primary mt-4"
                  aria-label="submit data"
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PasswordUpdate;
