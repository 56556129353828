import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../config';
import { ERROR_MESS } from '../../constant';
import { SHOW_ERROR, SHOW_SUCCESS } from '../../utils/toastMessages';

const initialState = {
  allCategories: [],
  childCategories: [],
  error: null,
  status: null,
};

export const getAllCatogries = createAsyncThunk(
  'get/workflow-approval-category',
  async () => {
    try {
      const response = await API.catogries.getAll();
      // // // //console.log(response);
      // // //console.log(response.data);
      return response.data.data;
    } catch (error) {
      // // //console.log(error.response.message);
      return error.response.data;
    }
  }
);

export const getChilrenCatogries = createAsyncThunk(
  'get/CATEGORY_CHILD',
  async (id) => {
    try {
      const response = await API.catogries.getChildren(id);
      // // // //console.log(response);
      // // //console.log(response.data);
      return response.data.data;
    } catch (error) {
      // // //console.log(error.response.message);
      return error.response.data;
    }
  }
);

export const addCategory = createAsyncThunk(
  'add/workflow-approval-category',
  async (data) => {
    try {
      const response = await API.catogries.add(data);
      // // //console.log(response.data);
      SHOW_SUCCESS(
        response.data.success,
        'Approval Category created successfully.'
      );
      return response.data;
    } catch (error) {
      const { code, success, message, errors } = error.response.data;

      // // //console.log(error.response.message);
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422 && errors.hasOwnProperty('name')) {
        SHOW_ERROR(true, errors.name[0]);
      } else {
        SHOW_ERROR(error, ERROR_MESS);
      }
      return error.response.data;
    }
  }
);

export const deleteCategory = createAsyncThunk(
  'delete/workflow-approval-category',
  async (id) => {
    try {
      const res = await API.catogries.delete(id);
      if (res.data.success) {
        SHOW_SUCCESS(true, 'Approval Category deleted successfully.');
      }
      // //console.log(res);
      return id;
    } catch (error) {
      // //console.log("category delete error", error);
      const { code, success, message, errors } = error.response.data;
      // console.log("error => ", error.response.data);
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 404) {
        SHOW_ERROR(true, message);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return error;
    }
  }
);

const CatogriesSlice = createSlice({
  name: 'Workflow approval category',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      // catogries redues
      .addCase(getAllCatogries.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllCatogries.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allCategories = action.payload;
      })
      .addCase(getAllCatogries.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      .addCase(getChilrenCatogries.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getChilrenCatogries.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.childCategories = action.payload?.child;
      })
      .addCase(getChilrenCatogries.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      .addCase(addCategory.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { success } = action.payload;
        if (success) {
          state.allCategories.unshift(action.payload.data);
        }
        state.error = action.payload?.message?.error;
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      .addCase(deleteCategory.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // //console.log("id", action.payload);
        state.allCategories = state.allCategories.filter(
          (item) => item.id !== action.payload
        );
      });
  },
});

export default CatogriesSlice.reducer;
