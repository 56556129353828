import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// local imports
import AppRoutes from "./routes";

import StaticSiteRoutes from "./site/routes";

function App() {
  const { pathname } = useLocation(); // <-- get current location being accessed

  useEffect(() => {
    window?.addEventListener("beforeunload", () => "hello from ");
    return () => {};
  }, []);

  if (pathname.includes("/register")) {
    return <StaticSiteRoutes />;
  } else {
    return <AppRoutes />;
  }
}

export default App;
