import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { API } from '../../config';
import { SHOW_ERROR, SHOW_SUCCESS } from '../../utils/toastMessages';
import { ERROR_MESS, FIXED_FORM_FIELD_CREATE } from '../../constant';

const initialState = {
  allForms: [],
  singleForm: '',
  fixedForms: [],
  Users: [],
  WorkFlowForm: {},
  workFlowForms: [],
  formSelectedType: '',
  enableForms: [],
  enableFormDetail: [],
  formFields: [],
  paginationData: {},
  error: null,
  status: null,
  navFlag: '',
};

export const fetchOneWorkFlowForm = createAsyncThunk(
  'get/WorkFlowForm',
  async (Id) => {
    try {
      const response = await API.WorkflowBuilder.getOne(Id);
      const { data, success } = response.data;
      // //console.log("inspection plan data", response);

      return { success, data, code: 200 };
    } catch (error) {
      // // // //console.log(error.response.data.message);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const fetchAllWorkFlowForm = createAsyncThunk(
  'get/AllWorkFlowForm',
  async () => {
    try {
      const response = await API.WorkflowBuilder.getAll();
      const { data, success } = response.data;
      // //console.log("inspection plan data", response);

      return { success, data, code: 200 };
    } catch (error) {
      // // // //console.log(error.response.data.message);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const getAllForms = createAsyncThunk(
  'get/workflow-form-builders',
  async () => {
    try {
      const response = await API.form.getAllForms();
      // //console.log("getAll Form response is", response);
      return response.data.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteForm = createAsyncThunk(
  'delete/workflow-form-builders',
  async ({ id, onResponse }) => {
    // //console.log('id is',id)
    try {
      const response = await API.form.delete(id);
      // //console.log(response.data);
      if (response.data.success) {
        toast.success('Form deleted successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      return id;
    } catch (error) {
      // // //console.log(error.response.data.message.error);
      const { code, success, message, errors } = error.response.data;
      // console.log("error => ", error.response.data);
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 404) {
        SHOW_ERROR(true, message);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }

      // onResponse?.(error.response.data);
      return error.response.data;
    }
  }
);

export const createForm = createAsyncThunk(
  'post/workflow-form-builders',
  async (formData) => {
    try {
      const response = await API.form.add(formData.submitData);
      const { success, data } = response.data;

      if (success) {
        SHOW_SUCCESS(success, 'Form created successfully!');
        const finalData = {
          ...data,
          form: data?.isWorkflowEnabled === '1' ? 'Flow Forms' : 'Entry Forms',
        };
        localStorage.setItem('updatedFormData', JSON.stringify(finalData));

        formData.navigate(`/updateForm`, { replace: true });
        // localStorage.setItem("formInfo", JSON.stringify(data));
      }

      return { data, success, code: 201 };
    } catch (error) {
      // //console.log(error);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const getAllFormFields = createAsyncThunk(
  'get/workflow-form-builder-fields/',
  async (data) => {
    try {
      const response = await API.form.getAllFormFields(data);
      // onResponse?.(response.data.data);
      return response.data.data;
    } catch (error) {
      // // //console.log(error.response.data.message.error);
      if (error) {
        toast.error('Something went wrong!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      // onResponse?.(error.response.data);
      return error.response.data;
    }
  }
);

export const createFormFields = createAsyncThunk(
  'post/workflow-form-builder-fields',
  async ({ data, onResponse, setSubItems, setFieldName, ref }) => {
    // //console.log("data is", data);
    try {
      const response = await API.form.AddFormFields(data);
      // //console.log(response.data);
      if (response.data.success) {
        setSubItems([]);
        setFieldName('');
        ref.current.value = '';
        SHOW_SUCCESS(response.data.success, 'Field created successfully!');
      }
      // onResponse?.(response.data.data);
      return response.data.data;
    } catch (error) {
      // // //console.log(error.response.data.message.error);
      if (error) {
        toast.error('Something went wrong!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      onResponse?.(error.response.data);
      return error.response.data;
    }
  }
);

export const deleteFormField = createAsyncThunk(
  'delete/workflow-form-builder-fields',
  async ({ id, onResponse }) => {
    try {
      const response = await API.form.deleteFormField(id);
      // //console.log(response.data);
      if (response.data.success) {
        toast.success('Field deleted successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      onResponse?.(response.data.data);
      return response.data.data;
    } catch (error) {
      // // //console.log(error.response.data.message.error);
      if (error) {
        toast.error('Something went wrong!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      onResponse?.(error.response.data);
      return error.response.data;
    }
  }
);

export const updateFormField = createAsyncThunk(
  'delete/workflow-form-builder-fields',
  async ({ data, onResponse, id }) => {
    try {
      // //console.log("data => ", data);
      const response = await API.form.updateFormField(data, id);
      // //console.log(response.data);
      if (response.data.success) {
        toast.success('Field updated successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      onResponse?.(response.data.data);
      return response.data.data;
    } catch (error) {
      // // //console.log(error.response.data.message.error);
      if (error) {
        toast.error('Something went wrong!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      onResponse?.(error.response.data);
      return error.response.data;
    }
  }
);

export const getEnablesForms = createAsyncThunk(
  'get/enabled-forms',
  async () => {
    try {
      const response = await API.form.getEnablesForms();
      // // // //console.log(response);
      // // //console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      // // //console.log(error.response.message);
      return error.response.data;
    }
  }
);

export const updateUserForm = createAsyncThunk(
  'put/workflow-form-builders',
  async ({ data, onResponse, id }) => {
    try {
      const response = await API.form.updateForm(data, id);
      if (response.data.success) {
        toast.success('Form updated successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      onResponse?.(response.data.data);
      return response.data.data;
    } catch (error) {
      if (error) {
        toast.error('Something went wrong!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      return error.response.data;
    }
  }
);

export const getFixForms = createAsyncThunk('get/fix-forms', async () => {
  try {
    const response = await API.form.getFixForms();
    // // // //console.log(response);
    // // //console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    // // //console.log(error.response.message);
    return error.response.data;
  }
});

export const getEnablesFormDetail = createAsyncThunk(
  `get/enabled-forms/detail/`,
  async (id) => {
    try {
      const response = API.form.getEnablesFormsDetails(id).then((res) => {
        return res.data.data;
      });
      return response;
      // //console.log("id is", response);
    } catch (error) {
      // // //console.log(error.response.message);
      return error.response.data;
    }
  }
);

export const createWorkflowEnableForm = createAsyncThunk(
  `post/workflow-approval`,
  async (id) => {
    try {
      const response = API.form.getEnablesFormsDetails(id).then((res) => {
        return res.data.data;
      });
      return response;
      // //console.log("id is", response);
    } catch (error) {
      // // //console.log(error.response.message);
      return error.response.data;
    }
  }
);

export const saveWorkflowEnableForm = createAsyncThunk(
  `post/workflow-approval`,
  async (formData) => {
    try {
      const response = await API.form.workFlowApprovalForm(formData.data);
      // //console.log(response.data);
      const { success, message, data } = response.data;

      if (success) {
        SHOW_SUCCESS(response.data.success, FIXED_FORM_FIELD_CREATE);
        formData.resetFom();
        formData.fileDelete();
      }
      return {
        success,
        data,
        code: 200,
      };
    } catch (error) {
      // //console.log(error);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        // for (const [key, value] of Object.entries(errors)) {
        // //console.log(
        //   "key => ",
        //   Object.entries(errors)[0][0],
        //   Object.entries(errors)[0][1][0]
        // );

        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const saveEnableForm = createAsyncThunk(
  `post/workflow-save-form`,
  async ({
    obj,
    setSelectedGroup,
    setSingleForm,
    setMediaId,
    singleForm,
    setIsImage,
    document,
  }) => {
    try {
      const response = await API.form.saveEnableForm(obj);
      const { success, data, message } = response.data;
      if (success) {
        SHOW_SUCCESS(success, 'Flow Form initiated successfully');
        setIsImage(undefined);
        document.getElementById('imgid').value = '';
        // //console.log(" singleForm.fields=>", singleForm.fields);

        let newResetArr = singleForm.fields.map((item, idx) => {
          return { ...item, value: '' };
        });
        // //console.log("newResetArr =>", newResetArr);
        setSelectedGroup('');
        setSingleForm({ ...singleForm, fields: newResetArr });
        setMediaId('');
      }
      return {
        success,
        data,
        code: 2000,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, errors[0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: [],
        code,
      };
    }
  }
);

// approvalTrail

export const getApprovalTrail = createAsyncThunk(
  'get/approvalTrail',
  async (Id) => {
    try {
      const response = await API.form.approvalTrail.get(Id);
      // // // //console.log(response);
      // // //console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      // // //console.log(error.response.message);
      return error.response.data;
    }
  }
);

const FormSlice = createSlice({
  name: 'Group',
  initialState,
  reducers: {
    setFieldType: (state, action) => {
      // state.formSelectedType = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createForm.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createForm.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 201) {
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
        }
      })
      .addCase(createForm.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(getEnablesForms.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getEnablesForms.fulfilled, (state, action) => {
        state.enableForms = action.payload;
        state.status = 'succeeded';
      })
      .addCase(getEnablesForms.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(getEnablesFormDetail.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getEnablesFormDetail.fulfilled, (state, action) => {
        state.enableFormDetail = action.payload;
        state.status = 'succeeded';
      })
      .addCase(getEnablesFormDetail.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(createFormFields.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createFormFields.fulfilled, (state, action) => {
        state.formFields.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(createFormFields.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(getAllFormFields.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllFormFields.fulfilled, (state, action) => {
        state.formFields = action.payload;

        state.status = 'succeeded';
      })
      .addCase(getAllFormFields.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(deleteFormField.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteFormField.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(deleteFormField.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(getAllForms.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllForms.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allForms = action.payload;
        state.paginationData = action.payload;
      })
      .addCase(getAllForms.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(deleteForm.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteForm.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allForms = state.allForms.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deleteForm.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(saveWorkflowEnableForm.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(saveWorkflowEnableForm.fulfilled, (state, action) => {
        const { code, success, data } = action.payload;
        if (success && code === 200) {
          state.status = 'succeeded';
          // //console.log(data);
        } else {
          state.status = 'failed';
        }
      })
      .addCase(saveWorkflowEnableForm.rejected, (state, action) => {
        state.status = 'failed';
        // //console.log(action.payload);
        state.error = action.payload;
      })

      .addCase(getFixForms.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getFixForms.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.fixedForms = action.payload;
      })
      .addCase(getFixForms.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      // fetchOneWorkFlowForm
      .addCase(fetchOneWorkFlowForm.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchOneWorkFlowForm.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = 'succeeded';
          state.WorkFlowForm = data;
        } else {
          state.status = 'failed';
          state.error = code;
        }
      })
      .addCase(fetchOneWorkFlowForm.rejected, (state, action) => {
        state.status = 'failed';
        // //console.log(action.payload);
        state.error = action.payload;
      })

      // fetchAllWorkFlowForm
      .addCase(fetchAllWorkFlowForm.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchAllWorkFlowForm.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = 'succeeded';
          state.workFlowForms = data;
        } else {
          state.status = 'failed';
          state.error = code;
        }
      })
      .addCase(fetchAllWorkFlowForm.rejected, (state, action) => {
        state.status = 'failed';
        // //console.log(action.payload);
        state.error = action.payload;
      })

      .addCase(saveEnableForm.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(saveEnableForm.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = 'succeeded';
          state.fixedForms = data;
        } else {
          state.status = 'failed';
          state.error = code;
        }
      })
      .addCase(saveEnableForm.rejected, (state, action) => {
        state.status = 'failed';
        // //console.log(action.payload);
        state.error = action.payload;
      })
      .addCase(updateUserForm.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateUserForm.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(updateUserForm.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      });
  },
});

export const { setFieldType } = FormSlice.actions;

export default FormSlice.reducer;
