import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BsEyeFill } from 'react-icons/bs';
// import { BiEditAlt } from "react-icons/bi";

import TableLoading from '../../../common/SkeltonLoading/TableLoading';
import { useDispatch, useSelector } from 'react-redux';
import MainBreadcrum from '../../../layout/MainBreadcrum';
import { getChilrenCatogries } from '../../../../Redux/features/CategorySlice';
import DataTable from '../../../common/DataTable';

const ApprovalCategoriesChild = () => {
  const { Id } = useParams();

  const dispatch = useDispatch();

  const {
    Catogries: { childCategories, status },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getChilrenCatogries(Id));
  }, [dispatch]);

  // //console.log("child categories", childCategories);

  //   setRejectionReason((reasjectionReason) => [
  //     ...reasjectionReason,
  //     reason,
  //   ]);
  // }

  // const handlePageClick = async (data) => {
  //   // // //console.log(data.selected);
  //   // scroll to the top
  //   //window.scrollTo(0, 0)
  // };

  return (
    <>
      <div className="row">
        <MainBreadcrum
          nav={true}
          path="/approval-categories/create"
          label="create"
          name="Approval Category"
          subName=""
          links={[
            { path: '/', title: '' },
            { path: '/approval-categories', title: 'Approval Category' },
            { path: '', title: 'Child', activeLink: true },
          ]}
        />
        {/* <div className="col-4 align-self-center text-end">
          <Link to="/approval-categories/create">
            <button type="button" className="btn btn-primary">
              Create
            </button>
          </Link>
        </div> */}
      </div>
      <div className="mt-3">
        <div
          className="mb-5 
                "
        >
          <div className="card">
            <div className="card-body">
              <div className="row mb-2"></div>
              <div className="">
                {status === 'loading' ? (
                  <>
                    <TableLoading />
                  </>
                ) : (
                  // <table className="table">
                  //   {/* {//console.log("from inside", approvalCategories)} */}
                  //   <thead>
                  //     <tr className="text-nowrap">
                  //       <th>ID</th>
                  //       <th>Name</th>
                  //       {/* <th>parent</th> */}
                  //       {/* <th className="text-center">Action</th> */}
                  //     </tr>
                  //   </thead>
                  //   <tbody className="table-border-bottom-0">
                  //     {approvalCategories[0]?.child?.map((cat) => (
                  //       <tr>
                  //         <th scope="row">{cat.id}</th>
                  //         <td>{cat.name}</td>
                  //         <td className="py-0">
                  //           {/* <div className="d-flex align-items-center justify-content-center">
                  //             <Link
                  //               className="dropdown-item hover-blue p-4 text-center me-4 rounded pointer"
                  //               to={`/approval-categories/child/${cat.id}`}
                  //             >
                  //               <MdTableView size={"1rem"} />
                  //             </Link>
                  //           </div> */}
                  //         </td>
                  //       </tr>
                  //     ))}
                  //   </tbody>
                  // </table>
                  <DataTable
                    headings={[
                      {
                        Header: 'Id',
                        disableFilters: true,
                        accessor: 'id',
                      },
                      {
                        Header: 'Name',
                        accessor: 'name',
                        disableFilters: true,
                        Cell: ({ row }) => (
                          <div className="p-4">{row.original?.name}</div>
                        ),
                      },
                    ]}
                    data={childCategories}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovalCategoriesChild;
