// import React from 'react'
// import { Collapse } from "react-bootstrap";
// import LinkItem from './LinkItem'
// import { Link } from 'react-router-dom';
// function SubMenuLinkItem({sub,open,setOpen}) {
//   console.log(`Subtest${sub.subMenuItems}`)
//    const changeStateHandler=()=>{setOpen(!open)}
//   return (
//     <>{ <div style={{marginLeft:'0.5rem'}}>{sub?.subMenuItems?.map(({link,label,icon,permission})=>( <LinkItem
//         link={link}
//         label={label}
//         icon={icon}
//         permission={permission}
//       subExist={sub.subMenuItems}
//         changeStateHandler={changeStateHandler}

//       />))}</div>}</>
//   )
// }

// export default SubMenuLinkItem

import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LinkItem from './LinkItem';
const SubMenuLinkItem = ({ link, label, icon, subMenu, permission }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Link
        onClick={() => setOpen(!open)}
        aria-controls={`#${link}`}
        aria-expanded={open}
        to={`#${link}`}
        // data-bs-toggle="collapse"
        className="menu-link align-middle menu-toggle"
      >
        {icon.includes('svg') ? (
          <img
            className="menu-icon tf-icons svg-icon"
            src={icon}
            alt={`${label} icon`}
          />
        ) : (
          <i className={icon} />
        )}
        <span className="d-none d-sm-inline">{label}</span>
      </Link>
      <Collapse in={open}>
        <ul
          className="nav flex-column ms-4"
          id={link}
          // data-bs-parent="#menu"
        >
          {subMenu.map((sub) => (
            <li className="menu-item">
              <LinkItem
                link={sub.link}
                label={sub.label}
                icon={sub.icon}
                permission={sub.permission}
              />
            </li>
          ))}
        </ul>
      </Collapse>
    </>
  );
};
export default SubMenuLinkItem;
// <>
//   <LinkItem link={link} label={label} icon={icon} />
//   <ul className="menu-sub">
// {subMenu.map((sub) => (
//   <li className="menu-item">
//     <LinkItem link={sub.link} label={sub.label} icon={sub.icon} />
//   </li>
// ))}
//   </ul>
// </>
