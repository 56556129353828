import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { API } from '../../config';
import {
  ERROR_MESS,
  REJECTION_REASON_CREATE,
  REJECTION_REASON_DELETE,
  REJECTION_REASON_UPDATE,
} from '../../constant';
import { SHOW_ERROR, SHOW_SUCCESS } from '../../utils/toastMessages';

const initialState = {
  allReasons: [],
  allChildReasons: [],
  oneReason: {},
  oneChild: {},
  paginationData: null,
  error: null,
  status: null,
};

export const fetchAllRejectedReasons = createAsyncThunk(
  'get/allRejectedReasons',
  async (page) => {
    try {
      const response = await API.rejectedReasons.getAll({
        key: page ? 'page' : '',
        value: page,
      });
      // // // //console.log(response.data);
      return response.data.data;
    } catch (error) {
      // // // //console.log(error.response.data?.message);
      return error.response.data;
    }
  }
);

export const fetchAllRejectedReasonsChild = createAsyncThunk(
  'get/allRejectedReasonsChild',
  async (page) => {
    try {
      const response = await API.rejectedReasons.getAllChilds({
        key: page ? 'page' : '',
        value: page,
      });
      // // // //console.log(response.data);
      return response.data.data;
    } catch (error) {
      // // // //console.log(error.response.data?.message);
      return error.response.data;
    }
  }
);

export const addRejectedReasonsData = createAsyncThunk(
  'post/RejectedReason',
  async (fromData) => {
    try {
      const response = await API.rejectedReasons.add(fromData.values);
      const { success, data } = response.data;
      if (success) {
        SHOW_SUCCESS(success, REJECTION_REASON_CREATE);
        fromData.resetForm();
      }
      return {
        success,
        data,
        code: 200,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;

      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(error, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const updateOneRejectedReason = createAsyncThunk(
  'update/rejectedReason',
  async (data) => {
    try {
      // // // //console.log("from slice :", data);
      const response = await API.rejectedReasons.update(
        data.id,
        data.finalData
      );
      SHOW_SUCCESS(response.data.success, REJECTION_REASON_UPDATE);
      return [];
    } catch (error) {
      // // // //console.log("FROM SLICE API ERROR", error);
      const { code, success, message, errors } = error.response.data;

      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422 && errors.hasOwnProperty('code')) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else if (!success && code === 422 && errors.hasOwnProperty('reason')) {
        SHOW_ERROR(true, errors.reason[0]);
      } else {
        SHOW_ERROR(error, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const deleteRejectedReasons = createAsyncThunk(
  'delete/rejectedReason',
  async (data) => {
    try {
      const response = await API.rejectedReasons.delete(data);
      // // // //console.log(response.data);
      SHOW_SUCCESS(response.data.success, REJECTION_REASON_DELETE);
      return data;
    } catch (error) {
      // // // //console.log(error.response.data.message);
      SHOW_ERROR(error, ERROR_MESS);
      return [];
    }
  }
);

export const fetchOneRejectedReasonData = createAsyncThunk(
  'get/rejectedReason',
  async (id) => {
    try {
      const response = await API.rejectedReasons.getOne(id);
      // // // //console.log(response.data);
      return response.data.data;
    } catch (error) {
      // // // //console.log(error.response.data.message);
      return error.response.data;
    }
  }
);

export const fetchOneChildRejectedReasonData = createAsyncThunk(
  'get/childRejectedReason',
  async (id) => {
    try {
      const response = await API.rejectedReasons.getOne(id);
      // // // //console.log(response.data);
      return response.data.data;
    } catch (error) {
      // // // //console.log(error.response.data.message);
      return error.response.data;
    }
  }
);

const RejectedReasonsSlice = createSlice({
  name: 'RejectedReasons',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllRejectedReasons.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchAllRejectedReasons.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allReasons = action.payload;
      })
      .addCase(fetchAllRejectedReasons.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchAllRejectedReasonsChild.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchAllRejectedReasonsChild.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allChildReasons = action.payload;
      })
      .addCase(fetchAllRejectedReasonsChild.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchOneRejectedReasonData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchOneRejectedReasonData.fulfilled, (state, action) => {
        state.oneReason = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchOneRejectedReasonData.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload?.message;
      })
      .addCase(fetchOneChildRejectedReasonData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchOneChildRejectedReasonData.fulfilled, (state, action) => {
        state.oneChild = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchOneChildRejectedReasonData.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload?.message;
      })
      .addCase(deleteRejectedReasons.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteRejectedReasons.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allReasons = state.allReasons.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deleteRejectedReasons.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload?.message;
      })
      .addCase(addRejectedReasonsData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addRejectedReasonsData.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = 'succeeded';
          state.allReasons.unshift(data);
        } else {
          state.status = 'failed';
        }
      })
      .addCase(addRejectedReasonsData.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(updateOneRejectedReason.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateOneRejectedReason.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // // // //console.log(action.payload);
        // state.RejectedReasons.push(action.payload);
      })
      .addCase(updateOneRejectedReason.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.payload);
        state.error = action.payload;
      });
  },
});

// export const { postAdded, reactionAdded } = RejectedReasons.actions;

export default RejectedReasonsSlice.reducer;
