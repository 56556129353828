import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { API } from '../../config';
import { toast } from 'react-toastify';
import { SHOW_ERROR, SHOW_SUCCESS } from '../../utils/toastMessages';
import _ from 'lodash';
import { ERROR_MESS } from '../../constant';

const initialState = {
  allPermissions: [],
  assignedPermissions: [],
  permission: {},
  error: null,
  status: null,
};

export const getAllPermissions = createAsyncThunk(
  'get/allPermissions',
  async () => {
    try {
      const {
        data: { data },
      } = await API.Permissions.getAll();

      // console.log("permissions , ", data);
      // //console.log(response.data.data);
      return data;
    } catch (error) {
      //console.log(error.response.message);
      return error.response.data;
    }
  }
);

export const fetchOneAssignedPermissionData = createAsyncThunk(
  'get/assignedPermission',
  async (id) => {
    try {
      let [response, resAllPermissions] = await Promise.all([
        API.Permissions.getAllAssigned(id),
        API.Permissions.getAll(),
      ]);

      const ALL_PERM = resAllPermissions.data.data;
      // //console.log(ALL_PERM);
      let assTempArr = [];
      let assAllTempArr = [];
      const {
        success,
        message,
        data: { assignedPermissions },
      } = response.data;

      if (success) {
        if (assignedPermissions !== undefined) {
          for (const [key, value] of Object.entries(assignedPermissions)) {
            assTempArr.push(parseInt(key));
          }
        }

        ALL_PERM.forEach(
          ({ id, name, is_company, form_name, description }, i, arr) => {
            // //console.log(id);
            if (assTempArr.includes(id)) {
              assAllTempArr.push({
                id,
                name,
                hashed: true,
                is_company,
                form_name,
                description,
              });
            } else {
              assAllTempArr.push({
                id,
                name,
                hashed: false,
                is_company,
                form_name,
                description,
              });
            }
          }
        );
      }

      // console.log("assAllTempArr :", assAllTempArr);

      // console.log(
      //   "SORTED :",
      let sortedPermissionArray = assAllTempArr.sort((r1, r2) =>
        r1.form_name > r2.form_name ? 1 : r1.form_name < r2.form_name ? -1 : 0
      );
      // );

      return {
        success,
        data: {
          selectedArry: assTempArr,
          allPermissions: sortedPermissionArray,
        },
        code: 200,
      };
    } catch (error) {
      // //console.log(error.response.data );
      // // // //console.log(error.response.data.message);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const AssignNewPermissions = createAsyncThunk(
  'post/assignPermission',
  async (pData) => {
    try {
      const response = await API.Permissions.assignNew(pData.id, pData.data);
      const resAllPermissions = await API.Permissions.getAll();
      const { success, message, data } = response.data;
      const ALL_PERM = resAllPermissions.data.data;
      let sortedPermissionArray = ALL_PERM.sort((r1, r2) =>
        r1.form_name > r2.form_name ? 1 : r1.form_name < r2.form_name ? -1 : 0
      );

      SHOW_SUCCESS(success, message);
      sortedPermissionArray.forEach(
        ({ id, name, is_company, form_name, description }, i, arr) => {
          if (pData.data.permissions.includes(id)) {
            ALL_PERM[i] = {
              id,
              name,
              hashed: true,
              is_company,
              form_name,
              description,
            };
          } else {
            ALL_PERM[i] = {
              id,
              name,
              hashed: false,
              is_company,
              form_name,
              description,
            };
          }
        }
      );
      if (success) {
        pData.setEditToggle(true);
      }

      return {
        success,
        data: {
          selectedArry: pData.data.permissions,
          allPermissions: ALL_PERM,
        },
        code: 201,
      };
    } catch (error) {
      // // // //console.log(error.response.data.message);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

const userPermissionSlice = createSlice({
  name: 'userPermissions',
  initialState,
  reducers: {
    setAllPermissions: (state, action) => {
      const { flag } = action.payload;

      state.allPermissions.forEach((perm) => {
        perm.hashed = flag;
      });

      if (flag) {
        state.assignedPermissions = state.allPermissions?.map(
          (perm) => perm.id
        );
      } else {
        state.assignedPermissions = [];
      }
    },

    checkSinglePermission: (state, action) => {
      const { flag, id } = action.payload;

      state.allPermissions.forEach((perm) => {
        if (perm.id === id) {
          perm.hashed = flag;
          if (flag) {
            state.assignedPermissions.push(perm.id);
          } else {
            state.assignedPermissions = state.assignedPermissions.filter(
              (perm) => perm !== id
            );
          }
        }
      });
    },

    setAllSelectedPermissions: (state, action) => {
      state.assignedPermissions = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllPermissions.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allPermissions = action.payload.data;
      })
      .addCase(getAllPermissions.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      // get permission data reduces
      .addCase(fetchOneAssignedPermissionData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchOneAssignedPermissionData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const {
          data: { allPermissions, selectedArry },
        } = action.payload;

        state.allPermissions = allPermissions;
        state.assignedPermissions = selectedArry;
      })

      .addCase(fetchOneAssignedPermissionData.rejected, (state, action) => {
        state.status = 'failed';
        // //console.log(action.payload);
        // state.error = action.payload;
      })
      // delete permission data reduces

      .addCase(AssignNewPermissions.pending, (state, action) => {
        state.status = 'loading';
        // state.allPermissions = [];
      })
      .addCase(AssignNewPermissions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const {
          data: { allPermissions, selectedArry },
        } = action.payload;

        state.allPermissions = allPermissions;
        state.assignedPermissions = selectedArry;
      })
      .addCase(AssignNewPermissions.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      });
  },
});

export const {
  setAllPermissions,
  setAllSelectedPermissions,
  checkSinglePermission,
} = userPermissionSlice.actions;

export default userPermissionSlice.reducer;

export const allPermissionsSelector = (state) =>
  state.UserPermission.allPermissions;
export const assignedPermissionsSelector = (state) =>
  state.UserPermission.assignedPermissions;
