import React from 'react';
import { AiFillHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const BreadcrumHomeLink = ({ path }) => {
  return (
    <div className={` home-arrow`}>
      <Link to={path}>
        <h6>
          <u>Home</u>
          {' >'}{' '}
        </h6>
        {/* <AiFillHome color="#0D488B" size={"1.7rem"} className="pt-1 me-10" /> */}
      </Link>
    </div>
  );
};

export default BreadcrumHomeLink;
