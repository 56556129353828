import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { API } from '../../config';
import { SHOW_ERROR, SHOW_INFO } from '../../utils/toastMessages';

const initialState = {
  intent: '',
  charges: false,
  registeredCompany: {
    name: '',
    contactPersonFirstName: '',
    contactPersonLastName: '',
    contactPersonPhoneNo: '',
    contactPersonEmail: '',
    test: '',
  },
  error: null,
  PPlan_id: {},
  status: null,
};

export const getIntent = createAsyncThunk('get/intent', async () => {
  try {
    const response = await API.Stripe.getIntent();
    // //console.log(response.data);
    const {
      success,
      data: { client_secret },
    } = response.data;

    return { success, client_secret, code: 200 };
  } catch (error) {
    const { code, success, message, errors } = error.response.data;
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 422) {
      SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
    } else {
      SHOW_ERROR(true, 'Something Went Wrong !');
    }
    return {
      success,
      data: {},
      code,
    };
  }
});

export const createStripeCharge = createAsyncThunk(
  'STRIPE_CHARGE',
  async (formData, thunkAPI) => {
    try {
      const response = await API.Stripe.StripeCharge(formData?.payData);
      const { success, data } = response.data;
      formData?.navigate('/login');
      SHOW_INFO(success, 'Payment Successful');
      // formData?.hide();

      thunkAPI.dispatch(resetCharge());
      return {
        success,
        data,
        code: 201,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);
export const createFreeUsers = createAsyncThunk(
  'STRIPE_CHARGE',
  async (formData, thunkAPI) => {
    try {
      const response = await API.Stripe.CreateFreeUser(formData?.payData);
      const { success, data } = response.data;
      formData?.navigate('/login');
      SHOW_INFO(success, 'Registration Successful');
      // formData?.hide();

      thunkAPI.dispatch(resetCharge());
      return {
        success,
        data,
        code: 201,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

const StripeSlice = createSlice({
  name: 'stripeCharges',
  initialState,
  reducers: {
    setPlanId: (state, action) => {
      state.PPlan_id = action.payload;
    },
    registerCompany: (state, action) => {
      const { companyData, setFormNo } = action.payload;
      // //console.log("action => ", action.payload);
      if (companyData) {
        state.status = 'succeeded';
        state.registeredCompany = companyData;
        setFormNo(2);
      } else {
        state.status = 'failed';
      }
    },
    resetCharge: (state, action) => {
      state.registeredCompany = {};
      // state.charges = false;
    },
  },
  extraReducers(builder) {
    builder

      // post stripe charges data reduces
      .addCase(getIntent.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getIntent.fulfilled, (state, action) => {
        const { success, client_secret, code } = action.payload;

        if (success && code === 200) {
          state.status = 'succeeded';
          state.intent = client_secret;
          state.registeredCompany.test = 'done';
        } else {
          state.status = 'failed';
        }
      })
      .addCase(getIntent.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      // post stripe charges data reduces
      .addCase(createStripeCharge.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(createStripeCharge.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 201) {
          state.status = 'succeeded';
          state.charges = true;
          state.registeredCompany.test = '';
        } else {
          state.status = 'failed';
        }
      })
      .addCase(createStripeCharge.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      });
  },
});
export const { setPlanId, resetCharge, registerCompany } = StripeSlice.actions;

export default StripeSlice.reducer;
