import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../config';

const initialState = {
  companyPlans: [],
  companyPlan: {},
  error: null,
  status: null,
};

export const getAllCompanyPlans = createAsyncThunk(
  'get/companyPlans',
  async () => {
    try {
      const response = await API.companyPlans.getAll();
      // //console.log("compnay plans", response.data);
      return response.data.data;
    } catch (error) {
      // //console.log("company plans error", error);
      return error.response.data;
    }
  }
);

export const getOneCompanyPlan = createAsyncThunk(
  'get/oneCompanyPlan',
  async (id) => {
    try {
      const response = await API.companyPlans.getOne(id);
      return response.data.data;
    } catch (error) {
      // //console.log("company plans error", error);
      return error.response.data;
    }
  }
);

const CompanyPlansSlice = createSlice({
  name: 'Company Plans',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(getAllCompanyPlans.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllCompanyPlans.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companyPlans = action.payload?.companyPlans;
      })
      .addCase(getAllCompanyPlans.rejected, (state, action) => {
        state.status = 'failed';
      })

      .addCase(getOneCompanyPlan.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getOneCompanyPlan.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companyPlan = action.payload;
      })
      .addCase(getOneCompanyPlan.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export default CompanyPlansSlice.reducer;
