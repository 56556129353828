import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StripeCheckout from 'react-stripe-checkout';
import {
  addChargesData,
  getIntent,
  resetCharge,
} from '../../../../Redux/features/StripeSlice';
import { Card, Col, Form, Row, Button, ButtonGroup } from 'react-bootstrap';
import { handelState } from '../../../../utils/handelStates';
import StripModal from '../../modal/StripModal';
import { setPlanId } from '../../../../Redux/features/StripeSlice';

const SinglePlan = ({ plan, check }) => {
  const [monthPlan, setMonthPlan] = useState({});
  const [planDetail, setPlanDetail] = useState({});

  useEffect(() => {
    if (check) {
      setMonthPlan({
        ...plan,
        sensorPrice: plan?.sensorPricePerMonth,
        userPrice: plan?.userPricePerMonth,
        storagePrice: plan?.storagePricePerMonth,
      });
      setPlanDetail({
        planType: 'monthly',
        noOfUsers: 2,
        noOfSensors: 2,
        storage: 2,
        totalAmount:
          2 * plan?.sensorPricePerMonth +
          2 * plan?.userPricePerMonth +
          2 * plan?.storagePricePerMonth,
      });
    } else {
      setMonthPlan({
        ...plan,
        sensorPrice: plan?.sensorPricePerYear,
        userPrice: plan?.userPricePerYear,
        storagePrice: plan?.storagePricePerYear,
      });

      setPlanDetail({
        planType: 'yearly',
        noOfUsers: 2,
        noOfSensors: 2,
        storage: 2,
        totalAmount:
          2 * plan?.sensorPricePerYear +
          2 * plan?.userPricePerYear +
          2 * plan?.storagePricePerYear,
      });
    }

    return () => {};
  }, [check, plan]);

  // local states

  const [modalShow, setModalShow] = React.useState(false);

  const dispatch = useDispatch();

  const { charges } = useSelector((state) => state.Stripe);

  useEffect(() => {
    if (charges) {
      dispatch(resetCharge());
      setModalShow(false);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charges]);

  const handleInputField = (e) => {
    if (e.target.name === 'noOfUsers') {
      setPlanDetail((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
          totalAmount:
            e.target.value * monthPlan?.userPrice +
            prev.noOfSensors * monthPlan?.sensorPrice +
            prev.storage * monthPlan?.sensorPrice,
        };
      });
    }
    if (e.target.name === 'noOfSensors') {
      setPlanDetail((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
          totalAmount:
            prev.noOfUsers * monthPlan?.userPrice +
            e.target.value * monthPlan?.sensorPrice +
            prev.storage * monthPlan?.storagePrice,
        };
      });
    }
    if (e.target.name === 'storage') {
      setPlanDetail((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value,
          totalAmount:
            prev.noOfUsers * monthPlan?.userPrice +
            prev.noOfSensors * monthPlan?.sensorPrice +
            e.target.value * monthPlan?.storagePrice,
        };
      });
    }
  };

  return (
    <>
      <Card className="m-2">
        <Row className="app-brand-logo my-2 mt-4">
          <Col className="d-flex align-items-center justify-content-center">
            <img
              height={'27px'}
              src="\assets\img\Process-Intel.svg"
              alt="Logo"
            />
          </Col>
        </Row>
        <Card.Header className="pb-0">
          <Card.Title className="text-center fs-4">
            {monthPlan?.name}
          </Card.Title>
        </Card.Header>
        <Card.Header className="py-0">
          <Card.Title className="text-center fs-6 text-secondary">
            <p className="py-0 my-0">
              {monthPlan?.description
                ? monthPlan?.description
                : 'Not Available'}
            </p>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Form>
              <Form.Group className="mb-3 mx-3" controlId="noOfUsers">
                <Row>
                  <Col xs="3" className="px-0 m-auto">
                    <Form.Label> No. of Users:</Form.Label>
                  </Col>
                  <Col xs="3" className="px-0 m-auto">
                    <Form.Control
                      className="py-1"
                      type="number"
                      defaultValue={planDetail.noOfUsers}
                      name="noOfUsers"
                      onChange={handleInputField}
                      placeholder="No Of Users"
                    />
                  </Col>
                  <Col xs="3" className="m-auto ps-2 pe-0 text-center">
                    ✖ <p className="fs-6 d-inline">{monthPlan?.userPrice}=</p>
                  </Col>
                  <Col xs="3" className="px-0 m-auto">
                    <Form.Control
                      readOnly={true}
                      className="py-1"
                      type="number"
                      value={planDetail.noOfUsers * monthPlan?.userPrice}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3 mx-3" controlId="noOfSensors">
                <Row>
                  <Col xs="3" className="px-0 m-auto">
                    <Form.Label> No. of Sensors:</Form.Label>
                  </Col>
                  <Col xs="3" className="px-0 m-auto">
                    <Form.Control
                      className="py-1"
                      defaultValue={planDetail.noOfSensors}
                      type="number"
                      name="noOfSensors"
                      onChange={handleInputField}
                      placeholder="No Of Sensors"
                    />
                  </Col>
                  <Col xs="3" className="m-auto ps-2 pe-0 text-center">
                    ✖ <p className="fs-6 d-inline">{monthPlan?.sensorPrice}=</p>
                  </Col>
                  <Col xs="3" className="px-0 m-auto">
                    <Form.Control
                      readOnly={true}
                      className="py-1"
                      value={planDetail.noOfSensors * monthPlan?.sensorPrice}
                      type="number"
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3 mx-3" controlId="noOfstorage">
                <Row>
                  <Col xs="3" className="px-0 m-auto">
                    <Form.Label>No. of Storage( Gb ):</Form.Label>
                  </Col>
                  <Col xs="3" className="px-0 m-auto">
                    <Form.Control
                      className="py-1"
                      type="number"
                      defaultValue={planDetail.storage}
                      name="storage"
                      onChange={handleInputField}
                      placeholder="How Many Gbs"
                    />
                  </Col>
                  <Col xs="3" className="m-auto ps-2 pe-0 text-center">
                    ✖{' '}
                    <p className="fs-6 d-inline">{monthPlan?.storagePrice}=</p>
                  </Col>
                  <Col xs="3" className="px-0 m-auto">
                    <Form.Control
                      readOnly={true}
                      className="py-1"
                      type="number"
                      value={planDetail.storage * monthPlan?.storagePrice}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Row>
        </Card.Body>
        <Card.Footer className="text-center">
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              onClick={() => {
                setModalShow(true);
                dispatch(setPlanId({ ...planDetail, planId: plan.id }));
              }}
              className="btn-primary"
            >
              Buy
            </Button>
            <Button className="btn-primary" disabled>
              {planDetail?.totalAmount?.toFixed(2)}
            </Button>
          </ButtonGroup>
        </Card.Footer>
        {/* <Card.Footer>
          <Row className="justify-content-xs-center">
            <Col>
              <Button
                className="bg-secondary"
                onClick={() => {
                  setModalShow(true);
                  dispatch(setPlanId({ ...planDetail, planId: plan.id }))
                }}
              >
                Buy
              </Button>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="totalAmount">
                <Form.Control
                  readOnly={true}
                  type="text"
                  name="totalAmount"
                  defaultValue={planDetail.totalAmount}
                  value={planDetail.totalAmount}
                  placeholder="Total Amount"
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Footer> */}
      </Card>
      <StripModal
        // planDetail={{ ...planDetail, planId: plan.id }}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default SinglePlan;
