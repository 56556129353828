import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { API } from '../../config';
import {
  ERROR_MESS,
  MATERIAL_MASTER_CREATE,
  MATERIAL_MASTER_DELETE,
  MATERIAL_MASTER_UPDATE,
} from '../../constant';
import { SHOW_ERROR, SHOW_INFO, SHOW_SUCCESS } from '../../utils/toastMessages';
import { clearFileState } from './AppUtilsSlice';

const initialState = {
  materials: [],
  material: {},
  paginationData: null,
  error: null,
  status: null,
};

export const fetchAllMaterials = createAsyncThunk(
  'get/allMaterials',
  async (page) => {
    try {
      const response = await API.MaterialMaster.getAll({
        key: page ? 'page' : '',
        value: page,
      });
      // // // //console.log(response.data);

      return {
        page,
        data: response.data.data,
      };
    } catch (error) {
      // // // //console.log(error.response.data?.message);
      return error.response.data;
    }
  }
);

export const syncMaterial = createAsyncThunk('sync/material', async () => {
  try {
    const res = await API.MaterialMaster.syncMaterial();
    // //console.log("sync res", res);
  } catch (error) {
    // //console.log("sync error", error);
    return error;
  }
});

export const exportMaterial = createAsyncThunk('export/material', async () => {
  try {
    const res = await API.MaterialMaster.export();
    console.log('sync res', res.data);
  } catch (error) {
    // //console.log("sync error", error);
    return error;
  }
});

export const exportMaterialTemplate = createAsyncThunk(
  'export/materialTemplate',
  async () => {
    try {
      const res = await API.MaterialMaster.templateExport();
      // console.log("res", res.data);
    } catch (error) {
      // //console.log("sync error", error);
      return error;
    }
  }
);

export const importMaterialTemplate = createAsyncThunk(
  'import/materialTemplate',
  async (data) => {
    try {
      const res = await API.MaterialMaster.templateImport(data);
      if (res.data.success) {
        SHOW_SUCCESS(true, res.data.message);
      }
      return res.data;
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return error;
    }
  }
);

export const addMaterialData = createAsyncThunk(
  'post/material',
  async (formdata, thunkAPI) => {
    try {
      const response = await API.MaterialMaster.add(formdata.materialFormData);
      // // // //console.log(response.data);
      const { success, data } = response.data;
      // const success = true;
      if (success) {
        formdata.clear.clearValue();
        formdata.setMaterialFormData(formdata.initState);
        SHOW_SUCCESS(success, MATERIAL_MASTER_CREATE);
        thunkAPI.dispatch(clearFileState());
      }

      return {
        success: false,
        data: [],
        code: 200,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      // // //console.log(error.response.data.message);
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const updateOneMaterialMaster = createAsyncThunk(
  'update/material',
  async (formData) => {
    try {
      // // // //console.log("from slice :", data);
      const response = await API.MaterialMaster.update(
        formData.id,
        formData.finalData
      );
      const { success, data } = response.data;
      if (success) {
        SHOW_SUCCESS(data, MATERIAL_MASTER_UPDATE);
        formData.navigate('/material');
        formData.setEditToggle(true);
      }
      return [data, success];
    } catch (error) {
      // //console.log("error => ", error);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const deleteMaterialMaster = createAsyncThunk(
  'delete/material',
  async (data) => {
    try {
      const response = await API.MaterialMaster.delete(data);
      // // // //console.log(response.data);
      SHOW_SUCCESS(response.data.success, MATERIAL_MASTER_DELETE);
      return data;
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      // console.log("error => ", error.response.data);
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 404) {
        SHOW_ERROR(true, message);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return [];
    }
  }
);

export const fetchOneMaterialMasterData = createAsyncThunk(
  'get/material',
  async (id) => {
    try {
      const response = await API.MaterialMaster.getOne(id);
      // // // //console.log(response.data);
      return response.data.data;
    } catch (error) {
      // // // //console.log(error.response.data.message);
      return error.response.data;
    }
  }
);

const MaterialMasterSlice = createSlice({
  name: 'MaterialMaster',
  initialState,
  reducers: {
    updateMaterialPlants: (state, { payload }) => {
      let tempPlant = [...state.material.plants];
      let fil = tempPlant.filter(
        (f) => f.value !== payload.payload.includes(payload)
      );
      state.material.plants.push(fil);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllMaterials.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchAllMaterials.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { page, data } = action.payload;

        if (page) {
          state.materials = data.materialMasters;
          // // // //console.log(action.payload.materialMasters);
          delete data.materialMasters;
          state.paginationData = data;
        } else {
          state.materials = data;
        }
      })
      .addCase(fetchAllMaterials.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.payload);
        state.error = action.payload;
      })
      .addCase(fetchOneMaterialMasterData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchOneMaterialMasterData.fulfilled, (state, action) => {
        const tempArry = action.payload.plants.map((p) => {
          return { value: p.id, label: p.plantName };
        });
        const tempPlantId = action.payload.plants.map((p) => {
          return p.id;
        });
        state.material = action.payload;
        state.material.plants = tempArry;
        state.material.plantIds = tempPlantId;
        state.status = 'succeeded';
      })
      .addCase(fetchOneMaterialMasterData.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(deleteMaterialMaster.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteMaterialMaster.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.materials = state.materials.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deleteMaterialMaster.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(addMaterialData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addMaterialData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.success) {
          state.materials.unshift(action.payload);
          state.error = 200;
        } else {
          state.error = 400;
        }
        // state.error = null;
      })
      .addCase(addMaterialData.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(updateOneMaterialMaster.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateOneMaterialMaster.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // // // //console.log(action.payload);
        // state.materials.push(action.payload);
      })
      .addCase(updateOneMaterialMaster.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.payload);
        state.error = action.payload;
      })

      .addCase(exportMaterial.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(exportMaterial.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(exportMaterial.rejected, (state, action) => {
        state.status = 'failed';
      })

      .addCase(exportMaterialTemplate.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(exportMaterialTemplate.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(exportMaterialTemplate.rejected, (state, action) => {
        state.status = 'rejected';
      })

      .addCase(importMaterialTemplate.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(importMaterialTemplate.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(importMaterialTemplate.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const { updateMaterialPlants } = MaterialMasterSlice.actions;

export default MaterialMasterSlice.reducer;
