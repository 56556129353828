import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { BiEditAlt } from "react-icons/bi";
import { MdTableView } from 'react-icons/md';

import TableLoading from '../../../common/SkeltonLoading/TableLoading';
import { useDispatch, useSelector } from 'react-redux';
import MainBreadcrum from '../../../layout/MainBreadcrum';
import {
  deleteCategory,
  getAllCatogries,
} from '../../../../Redux/features/CategorySlice';
import DataTableWithExcel from '../../../common/DataTableWithExcel';
import { RiDeleteBin6Line } from 'react-icons/ri';
import DeletePopup from '../../../common/DeletePopup';

const ApprovalCategories = () => {
  // const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [getId, setId] = useState(null);

  const [approvalCategories, setApprovalCategories] = useState([]);

  const dispatch = useDispatch();

  const {
    Catogries: { allCategories, status },
  } = useSelector((state) => state);
  // const { permissions } = useSelector((state) => state.Auth);

  useEffect(() => {
    dispatch(getAllCatogries());
  }, [dispatch]);

  useEffect(() => {
    setApprovalCategories(allCategories?.filter((cat) => cat.parentId == null));
  }, [allCategories]);

  const categoriesData = React.useMemo(
    () => approvalCategories,
    [approvalCategories]
  );
  const columnsData = React.useMemo(
    () => [
      {
        Header: 'ID',
        disableFilters: true,
        accessor: 'id',
      },
      {
        Header: 'Name',
        disableFilters: true,
        accessor: 'name',
      },

      {
        id: 'Action',
        Header: 'Action',
        disableFilters: true,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center justify-content-center">
            <Link
              className="dropdown-item hover-blue p-4 text-center me-4 rounded pointer"
              to={`/approval-categories/child/${row.original.id}`}
            >
              <MdTableView size={'1rem'} />
            </Link>
            <span
              className="dropdown-item hover-danger p-4 text-center me-4 rounded pointer"
              onClick={() => {
                handleOpen();
                setId(row.original.id);
              }}
            >
              <RiDeleteBin6Line size={'1rem'} />
            </span>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="row">
        <MainBreadcrum
          nav={true}
          label="create"
          path="/approval-categories/create"
          name="Approval Category"
          subName=""
          links={[
            { path: '/', title: '' },
            { path: '', title: 'Approval Category', activeLink: true },
          ]}
        />
        {/* <div className="col-4 align-self-center text-end">
          <Link to="/approval-categories/create">
            <button type="button" className="btn btn-primary">
              Create
            </button>
          </Link>
        </div> */}
      </div>
      <div className="mt-3">
        <div
          className="mb-5 
                "
        >
          <div className="card">
            <div className="card-body">
              <div className="">
                {status === 'loading' ? (
                  <>
                    <TableLoading />
                  </>
                ) : (
                  <DataTableWithExcel
                    columns={columnsData}
                    data={categoriesData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeletePopup
        handleClose={handleClose}
        open={open}
        deleteFun={deleteCategory}
        id={getId}
      />
    </>
  );
};

export default ApprovalCategories;
