import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import LinkItem from './LinkItem';
import SubMenuLinkItem from './SubMenuLinkItem';
import { FaAngleRight } from 'react-icons/fa';

const SubLinkItem = ({ link, label, icon, subMenu, permission }) => {
  console.log('test 2-->', subMenu);
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);

  return (
    <>
      {/* <Link
        onClick={() => setOpen(!open)}
        aria-controls={`#${link}`}
        aria-expanded={open}
        to={`#${link}`}
        // data-bs-toggle="collapse"
        className="menu-link align-middle menu-toggle"
      > */}
      <div
        className="menu-link align-middle "
        data-bs-toggle="collapse"
        onClick={() => setOpen(!open)}
      >
        {icon.includes('svg') ? (
          <img
            className="menu-icon tf-icons svg-icon"
            src={icon}
            alt={`${label} icon`}
          />
        ) : (
          <i className={icon} />
        )}

        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '80%',
          }}
        >
          <span>{label}</span> <FaAngleRight />{' '}
        </span>
      </div>

      {/* </Link> */}
      <Collapse in={open}>
        <ul
          className="nav flex-column ms-4"
          id={link}
          // data-bs-parent="#menu"
        >
          {subMenu?.map((sub) => {
            // console.log("Test1-->",sub?.subMenuItems)
            console.log(`suhhjhj`, sub?.subMenuItems);
            return (
              <>
                {sub?.subMenuItems?.length > 0 ? (
                  <li
                    className="menu-item"
                    onClick={() => setOpenSubMenu(!openSubMenu)}
                  >
                    <SubMenuLinkItem
                      link={sub.link}
                      label={sub.label}
                      icon={sub.icon}
                      permission={sub.permission}
                      subMenu={sub.subMenuItems}
                    />
                  </li>
                ) : (
                  <NavLink
                    className="activeHeight"
                    onClick={(e) => e.preventDefault}
                    to={link}
                    activeClassName="active"
                  >
                    <LinkItem
                      link={sub.link}
                      label={sub.label}
                      icon={sub.icon}
                      permission={sub.permission}
                    />
                  </NavLink>
                )}

                {/* {openSubMenu &&  <Collapse in={openSubMenu}>
            <div> <SubMenuLinkItem sub={sub} open={openSubMenu} setOpen={setOpen}/></div>
            
           </Collapse>} */}
              </>
            );
          })}
        </ul>
      </Collapse>
    </>
  );
};

export default SubLinkItem;

// <>
//   <LinkItem link={link} label={label} icon={icon} />
//   <ul className="menu-sub">
// {subMenu.map((sub) => (
//   <li className="menu-item">
//     <LinkItem link={sub.link} label={sub.label} icon={sub.icon} />
//   </li>
// ))}
//   </ul>
// </>
