import { Box, Skeleton } from '@mui/material';
import { random } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import FormSkeletonLoading from './FormSkeletonLoading';
import TreeSkeletonLoading from './TreeSkeletonLoading';

const SoloFormLoading = ({ times }) => {
  return (
    <>
      <Box
        sx={{
          position: 'fixed !important',
          top: '50% !important',
          left: '55% !important',
        }}
      >
        <ThreeDots color="#000" />
      </Box>
      {/* <div className="row">
        <div className="col">
          <div className="row">
            <div className="col-12">
              <div>
                {times?.map((t) => (
                  <div style={{ height: "5vh" }} className="mb-3 row">
                    <div className="col-sm-3">
                      <Skeleton animation="pulse" />
                    </div>
                    <div className="col-sm-9">
                      <Skeleton animation="pulse" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default SoloFormLoading;
