import React, { useCallback, useState, useEffect } from 'react';
import produce from 'immer';
// local imports

import Footer from '../layout/components/Footer';
import Tree from '../Tree';
import MUITree from '../Tree/MUITree';
import DATA from '../../data/tree2.json';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInspectionPlans } from '../../Redux/features/InspectionPlanSlice';
import CUTable from '../common/CUTable';
import { getDashboardData } from '../../Redux/features/GraphsSlice';
import InspectionLotChart from './playground components/chart';
import { useNavigate } from 'react-router-dom';

const Playground = () => {
  const [treeData, setTreeData] = useState(DATA);
  const [currentNode, setCurrentNode] = useState({});
  const dispatch = useDispatch();
  const { dashboard } = useSelector((state) => state.Graphs);
  const [inspectors, setInspectors] = useState([]);
  console.log('niko1', dashboard);
  const handleToggle = useCallback((id) => {
    setTreeData(
      produce((draft) => {
        const form = draft.find((f) => f.id === '6');
        // // // //console.log(form.D);
        form.children = [
          {
            id: '9',
            label: 'cellphone',
          },
        ];
      })
    );
  }, []);

  useEffect(() => {
    if (!window.localStorage.getItem('companyId')) {
      window.location.replace('/company');
    }
  }, []);

  // useEffect(() => {
  //   dispatch(getDashboardData());
  //   return () => {};
  // }, []);

  function roundToTwo(num) {
    return +(Math.round(num + 'e+3') + 'e-3');
  }

  const storage = roundToTwo(dashboard?.data?.pieData?.totalStorage);

  // //console.log("stor", storage);
  useEffect(() => {
    setInspectors(dashboard?.data?.pieData?.topInspectors);
    return () => {};
  }, [dashboard?.data?.pieData?.topInspectors]);

  const yesterday = new Date();
  const ymm = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
  const Yyyyy = yesterday.getFullYear();
  const YesterdayDate = ymm + '-' + Yyyyy;

  // check if inpectors is iterable
  const isIterable = (obj) => {
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  };
  const ins = isIterable(inspectors);

  const insDescending = ins
    ? [...inspectors].sort((a, b) => (a.totalLots < b.totalLots ? 1 : -1))
    : [];

  return (
    <>
      {/* <div className="d-flex justify-content-center align-itms-center flex-nowrap">
        <Tree data={treeData} setCurrentNode={setCurrentNode} />
        <MUITree/>
        <div className="container">
          <button onClick={handleToggle} className="btn btn-sm btn-success m-2">
            add child
          </button>
          <button
            onClick={() => {
              setTreeData([
                ...treeData,
                { id: 12 * Math.random(), label: "Food" },
              ]);
            }}
            className="btn btn-sm btn-danger m-2"
          >
            add parent
          </button>

          <button
            onClick={() => {
              dispatch(fetchInspectionPlans());
            }}
            className="btn btn-sm btn-warning m-2"
          >
            send req
          </button>
        </div>
        <CUTable  data={} heading={headings}/>
      </div> */}
      {/* Content wrapper  */}
      <div className="content-wrapper">
        {/* Content */}

        <div className="container-xxl flex-grow-1 px-0">
          <div className="row">
            {/* Latest Update */}
            <div className="col-md-6 mb-4">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <h5
                    style={{ fontFamily: 'Rubik, sans-serif' }}
                    className="card-title mb-2"
                  >
                    Inspection Lots
                  </h5>
                  <h5
                    style={{ fontFamily: 'Rubik, sans-serif' }}
                    className="card-title mb-2"
                  >
                    {YesterdayDate}
                  </h5>
                </div>
                <InspectionLotChart />
              </div>
            </div>
            <div className="col-md-6 col-12 mb-4">
              <div className="card h-100">
                <div className="card-header d-flex justify-content-between">
                  <h5
                    style={{ fontFamily: 'Rubik, sans-serif' }}
                    className="card-title mb-2"
                  >
                    Top Inspectors
                  </h5>
                  <h5
                    style={{ fontFamily: 'Rubik, sans-serif' }}
                    className="card-title mb-2"
                  >
                    {YesterdayDate}
                  </h5>
                </div>
                <div className="card-body">
                  {insDescending?.map((t) => (
                    <ul className="p-0 m-0">
                      <li className="mb-3 d-flex justify-content-between">
                        <div className="d-flex align-items-center lh-1 me-3">
                          <span className="badge badge-dot bg-success me-2"></span>{' '}
                          {`${t?.firstName} ${t?.lastName}`}
                        </div>
                        <div className="d-flex gap-3">
                          <span className="fw-semibold">{t?.totalLots}</span>
                        </div>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
            {/* / Latest Update */}
            {/* All Users  */}
            <div className="col-md-6 col-12 mb-4">
              <div className="card h-100">
                <div className="card-header">
                  <h5
                    style={{ fontFamily: 'Rubik, sans-serif' }}
                    className="card-title mb-2"
                  >
                    {dashboard?.data?.pieData?.activePlan?.planType} Plan
                  </h5>
                </div>
                <div className="card-body">
                  <ul className="p-0 m-0">
                    <li className="mb-3 d-flex justify-content-between">
                      <div className="d-flex align-items-center lh-1 me-3">
                        <span className="badge badge-dot bg-success me-2"></span>{' '}
                        Users
                      </div>
                      <div className="d-flex gap-3">
                        {/* <span>29.5k</span> */}
                        <span className="fw-semibold">{`${
                          dashboard?.data?.pieData?.totalUsers
                        }/${
                          dashboard?.data?.pieData?.activePlan === null
                            ? '0'
                            : dashboard?.data?.pieData?.activePlan?.noOfUser
                        }`}</span>
                      </div>
                    </li>
                    <li className="mb-3 d-flex justify-content-between">
                      <div className="d-flex align-items-center lh-1 me-3">
                        <span className="badge badge-dot bg-danger me-2"></span>{' '}
                        Sensors
                      </div>
                      <div className="d-flex gap-3">
                        {/* <span>25.7k</span> */}
                        <span className="fw-semibold">{`${
                          dashboard?.data?.pieData?.totalSensors
                        }/${
                          dashboard?.data?.pieData?.activePlan === null
                            ? '0'
                            : dashboard?.data?.pieData?.activePlan?.noOfSensors
                        }`}</span>
                      </div>
                    </li>
                    <li className="mb-3 d-flex justify-content-between">
                      <div className="d-flex align-items-center lh-1 me-3">
                        <span className="badge badge-dot bg-info me-2"></span>{' '}
                        Storage
                      </div>
                      <div className="d-flex gap-3">
                        {/* <span>11.5k</span> */}
                        <span className="fw-semibold">{`${storage}/${
                          dashboard?.data?.pieData?.activePlan === null
                            ? '0'
                            : dashboard?.data?.pieData?.activePlan?.storage
                        }`}</span>
                      </div>
                    </li>
                  </ul>
                  <div className="d-flex justify-content-center">
                    <div>
                      <label className="mx-4" htmlFor="">
                        Starting Date
                      </label>
                      <label className="mx-4 d-block" htmlFor="">
                        {dashboard?.data?.pieData?.activePlan?.planStarts}
                      </label>
                    </div>

                    <div>
                      <label className="mx-4" htmlFor="">
                        Ending Date
                      </label>
                      <label className="mx-4 d-block" htmlFor="">
                        {dashboard?.data?.pieData?.activePlan?.planEnds}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* / All Users  */}

            {/* Statistics cards & Revenue Growth Chart */}
            <div className="col-md-6 col-12">
              <div className="row">
                {/* Statistics Cards */}
                <div className="col-6 col-md-3 col-lg-6 mb-4">
                  <div className="card h-100">
                    <div className="card-body text-center">
                      <div className="avatar mx-auto mb-2">
                        <span className="avatar-initial rounded-circle bg-label-success">
                          <i className="bx bx-purchase-tag fs-4"></i>
                        </span>
                      </div>
                      <span className="d-block">Total Materials</span>
                      <h2 className="mb-0 fw-bolder">
                        {dashboard?.data?.pieData?.totalMaterials}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-3 col-lg-6 mb-4">
                  <div className="card h-100">
                    <div className="card-body text-center">
                      <div className="avatar mx-auto mb-2">
                        <span className="avatar-initial rounded-circle bg-label-danger">
                          <i className="bx bx-cart fs-4"></i>
                        </span>
                      </div>
                      <span className="d-block">Total Inspection Plans</span>
                      <h2 className="mb-0 fw-bolder">
                        {dashboard?.data?.pieData?.totalInspectionPlans}
                      </h2>
                    </div>
                  </div>
                </div>
                {/* Statistics Cards */}
                {/* Revenue Growth Chart */}
                {/* Revenue Growth Chart */}
              </div>
            </div>
            {/* Statistics cards & Revenue Growth Chart */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Playground;
