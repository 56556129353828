import EAPI from '../services/api';
import timeZone from '../data/timeZone.json';

export const key = {
  server: {
    url: process.env.REACT_APP_BASE_API_URL,
    api: 'https://dev-backend.processintel.com/api',
    // api: `${process.env.REACT_APP_BASE_API_URL}/api`,
  },
  app: {
    name: process.env.REACT_APP_NAME,
    url: process.env.REACT_APP_URL,
  },

  tree: {
    ACTION_TYPES: ['e', 'a'],
  },
  apiKey: { tinyEditor: process.env.REACT_APP_TINYMCE_API_KEY },
  auth: {
    clien_secret: process.env.REACT_APP_CLIENT_SECRET,
    client_id: process.env.REACT_APP_CLIEN_ID,
    grant_type: process.env.REACT_APP_GRANT_TYPE,
  },
  constant: {
    TIME_ZONE: timeZone,
  },
  token: null,
  compnyId: null,

  // {label:"", link:"", icon:""}
  sideBar: {
    sassMenuLink: [
      // {
      //   link: "/",
      //   icon: "/assets/img/inspection-plan.svg",
      //   label: "Dashboard",
      //   permission: "Dashboard-View",
      // },
      {
        link: '/company',
        icon: '/assets/img/inspection-plan.svg',
        label: 'Company Management',
        permission: 'Company-View',
      },
      {
        link: '/companyPlans',
        icon: '/assets/img/inspection-plan.svg',
        label: 'Company Plans',
        permission: 'Company-View',
      },
      {
        link: '/subscription',
        icon: '/assets/img/inspection-plan.svg',
        label: 'Subscription Plan',
        permission: 'Plan-View',
      },
      {
        link: '/adminRoles',
        icon: '/assets/img/inspection-plan.svg',
        label: 'Role Management',
        permission: 'Roles-View',
      },
      {
        link: '/transaction',
        icon: '/assets/img/inspection-plan.svg',
        label: 'Transaction History',
        permission: 'Transaction-View',
      },
      {
        link: '/consent',
        icon: '/assets/img/inspection-plan.svg',
        label: 'Registration Consent',
        permission: 'Registration-update',
      },
      {
        link: '/plan-subscription',
        icon: '/assets/img/inspection-plan.svg',
        label: 'Plan Subscription',
        // permission: "Plan-Create",
      },
      // {
      //   link: "/payment-method",
      //   icon: "/assets/img/inspection-plan.svg",
      //   label: "Payment Method",
      //   permission: "PaymentMethod-Add",
      // },
    ],
    companyMenuLink: [
      {
        link: '/',
        icon: '/assets/img/Main Categories/icon_dashboard.svg',
        label: 'Dashboard',
        permission: 'Dashboard-View',
        type: 'menu',
      },
      {
        link: '#submenu',
        icon: '/assets/img/Main Categories/icon_foundation.svg',
        label: 'Foundation',
        permission: [
          'Plant-View',
          'MaterialMaster-View',
          'BuildArea-View',
          'WorkStation-View',
          'InspectionPlan-View',
          'InspectionLot-View',
        ],
        subMenu: [
          {
            link: '/plant',
            icon: '/assets/img/Foundation/icon_plant.svg',
            label: 'Plant',
            permission: 'Plant-View',
            subMenu: [
              {
                link: '/plant',
                icon: '/assets/img/Foundation/icon_plant.svg',
                label: 'Plant',
                permission: 'Plant-View',
                type: 'menu',
              },
            ],
            //Submenu subitems===
            // subMenuItems:[
            //   { link: "/plant",
            //   icon: "/assets/img/Foundation/icon_plant.svg",
            //   label: "ABC",
            //   permission: "Plant-View",},
            //   { link: "/plant",
            //   icon: "/assets/img/Foundation/icon_plant.svg",
            //   label: "DEF",
            //   permission: "Plant-View",},
            // ]
          },

          {
            link: '/material',
            icon: '/assets/img/Foundation/icon_material master.svg',
            label: 'Material Master',
            permission: 'MaterialMaster-View',
            type: 'menu',
          },

          {
            link: '/build-area',
            icon: '/assets/img/Foundation/icon_build area.svg',
            label: 'Build Area',
            permission: 'BuildArea-View',
            type: 'menu',
          },

          {
            link: '/station',
            icon: '/assets/img/Foundation/icon_workstation.svg',
            label: 'Work Station',
            permission: 'WorkStation-View',
            type: 'menu',
          },

          {
            link: '/inspection-plan',
            icon: '/assets/img/Foundation/icon_inspection plan.svg',
            label: 'Inspection Plan',
            permission: 'InspectionPlan-View',
            type: 'menu',
          },

          {
            link: '/Inspection-lot',
            icon: '/assets/img/Foundation/icon_inspection lot.svg',
            label: 'Inspection Lot',
            permission: 'InspectionLot-View',
            type: 'menu',
          },
        ],
      },
      {
        link: '#submenu1',
        icon: '/assets/img/Main Categories/icon_form and flows.svg',
        label: 'Forms and Flows',
        permission: ['Form-Create', 'ApprovalGroup-View', 'Forms-View'],
        subMenu: [
          {
            link: '/form-builder',
            icon: '/assets/img/Forms and Flows/form.svg',
            label: 'Forms',
            permission: 'Forms-View',
            type: 'menu',
          },

          {
            link: '/group/list',
            icon: '/assets/img/Forms and Flows/icon_approval group.svg',
            label: 'Approval Group',
            permission: 'ApprovalGroup-View',
            type: 'menu',
          },

          {
            link: '/fixed-fields',
            icon: '/assets/img/Forms and Flows/icon_approval form.svg',
            label: 'Approval Form',
            permission: 'Form-Create',
            type: 'menu',
          },
        ],
      },
      {
        link: '#submenu2',
        icon: '/assets/img/Main Categories/icon_admin.svg',
        label: 'Admin',
        permission: ['Settings-View', 'Sensor-View', 'SensorType-View'],
        subMenu: [
          {
            link: '/',
            icon: '/assets/img/Admin/icon_settings.svg',
            label: 'Settings',
            permission: 'Settings-View',
            // subMenu: [
            //   {
            //     link: "/groups",
            //     icon: "/assets/img/Admin/icon_group.svg",
            //     label: "Group",
            //     permission: "Group-View",
            //   },

            // ],
            subMenuItems: [
              {
                link: '/rejection-reasons',
                icon: '/assets/img/Admin/icon_rejection reason.svg',
                label: 'Rejection Reason',
                permission: 'RejectionReason-View',
              },
              {
                link: '/user',
                icon: '/assets/img/Admin/icons_users.svg',
                label: 'Users',
                permission: 'User-View',
              },
              {
                link: '/approval-categories',
                icon: '/assets/img/inspection-plan.svg',
                label: 'Approval Category',
                permission: 'ApprovalCategories-View',
              },
              {
                link: '/companyRoles',
                icon: '/assets/img/Admin/icons_role management.svg',
                label: 'Role Management',
                permission: 'RoleManagement-View',
              },
              {
                link: '/approval-categories',
                icon: '/assets/img/Admin/icon_approval category.svg',
                label: 'Approval Category',
              },
              {
                link: '/material-no-config',
                icon: '/assets/img/Admin/icon_material no config.svg',
                label: 'Material No Config',
                permission: 'MaterialNoConfig-View',
              },
              {
                link: '/measurement-units',
                icon: '/assets/img/Admin/icon_measuring unit.svg',
                label: 'Measurement Unit',
                permission: 'MeasurementUnits-View',
              },
              {
                link: '/admin-log',
                icon: '/assets/img/Admin/icon_adming log.svg',
                label: 'Admin Logs',
                permission: 'Adminlog-View',
              },
              {
                link: '/groups',
                icon: '/assets/img/role-managment.svg',
                label: 'Group',
                permission: 'Group-View',
              },
            ],
          },
          {
            link: '/sensor',
            icon: '/assets/img/Admin/icon_sensor.svg',
            label: 'Sensor',
            permission: 'Sensor-View',
            type: 'menu',
          },
          {
            link: '/sensor-type',
            icon: '/assets/img/Admin/icon_sensor type.svg',
            label: 'Sensor Type',
            permission: 'SensorType-View',
            type: 'menu',
          },
        ],
      },
      {
        link: '#analytics',
        icon: '/assets/img/icon_analytics.svg',
        label: 'Analytics',
        permission: ['Report-View', 'Dashboard-View'],
        subMenu: [
          {
            link: '/reportsDashboard',
            icon: '/assets/img/Main Categories/icon_dashboard.svg',
            label: `Dashboard`,
            permission: 'Dashboard-View',
          },
          {
            link: 'reportSubmenu',
            icon: '/assets/img/inspection-plan.svg',
            label: 'Report',
            permission: 'Report-View',
            subMenuItems: [
              // {
              //   link: "/report/graph",
              //   icon: "/assets/img/inspection-plan.svg",
              //   label: "Graphs",
              //   permission: "Graph-Filter",
              // },
              // {
              //   link: "/reportsDashboard",
              //   icon: "/assets/img/inspection-plan.svg",
              //   label: "Dashboard",
              //   permission: "Dashboard-View"
              // },
              {
                link: '/report/general-form',
                icon: '/assets/img/Forms and Flows/form.svg',
                label: 'General Form',
                permission: 'GeneralForm-View',
              },
              {
                link: '/userEnableForm',
                icon: '/assets/img/Forms and Flows/icon_approval form.svg',
                label: 'Flow Form Trail',
                permission: 'Form-Trail',
              },
              {
                link: '/FixedFormDetail',
                icon: '/assets/img/Analytics/icon_approval trial.svg',
                label: 'Approval Trail',
                permission: 'Form-Trail-View',
              },
              // {
              //   link: "/FMEA",
              //   icon: "/assets/img/inspection-plan.svg",
              //   label: "FMEA",
              //   permission: "FMEA-View",
              // },
              // {
              //   link: '/report/fema',
              //   icon: '/assets/img/Analytics/icon_FMEA.svg',
              //   label: 'FMEA',
              //   permission: 'FMEA-Report-View',
              // },
              {
                link: '/report/fishbone',
                icon: '/assets/img/Analytics/icon_fishbone.svg',
                label: 'Fishbone',
                permission: 'Fishbone-View',
              },
              {
                link: '/report/5why',
                icon: '/assets/img/Analytics/icon_5y.svg',
                label: '5Ys',
                permission: 'Report2-View',
              },
              {
                link: '/inspection-lot-report',
                icon: '/assets/img/Analytics/icon_inspection lot report.svg',
                label: 'Inspection Lot Report',
                permission: 'InspectionLotReport-View',
              },
            ],
          },
        ],
      },
    ],
  },

  form: {
    WORK_FLOW: [
      { id: 1, type: 'Flow Forms' },
      { id: 0, type: 'Entry Forms' },
    ],

    TYPES: [
      { type: 1, title: 'Single Line Text Box' },
      { type: 2, title: 'Paragraph Line Text Box' },
      { type: 3, title: 'Drop Down' },
      { type: 4, title: 'Check Box' },
      { type: 5, title: 'Radio Button' },
    ],
  },

  approval: {
    ROLE: [
      { id: 'R', title: 'Viewer' },
      { id: 'A', title: 'Approver' },
    ],
  },

  initialState: {
    STEP: {
      ERROR: {
        title: null,
        orderNo: null,
        workstationIds: null,
      },
      STATE: {
        title: '',
        orderNo: '',
        description: '',
        workstationIds: '',
        workstation: [],
        goodImage: '',
      },
    },
    BUILD_CONDITION: {
      ERROR: {
        type: null,
        operator: null,
        frequency: null,
        noOfIteration: null,
        emails: null,
      },
    },

    QC_POINT: {
      STATE: {
        title: '',
        description: '',
        order: '',
        x: '',
        y: '',
        isMeasurement: '',
        passMinRange: '',
        passMaxRange: '',
        measurementUnitId: '',
      },
    },

    ALERT_CONDITION: {
      STATE: {
        type: '',
        operator: '',
        value: '',
        frequency: '',
        noOfIteration: '',
        action: '',
        emails: '',
      },
    },
  },

  permissions: {
    CompanyManagement: {
      CREATE: 'Company-Create',
      VIEW: 'Company-View',
      SELECT: 'Company-Select',
      DELETE: 'Company-Delete',
    },
    Plans: {
      CREATE: 'Plans-Create',
      PURCHASE: 'Plan-Purchase',
      EDIT: 'Plan-Edit',
      DELETE: 'Plan-Delete',
      VIEW: 'Plan-View',
    },
    AdminRoleManagement: {
      CREATE: 'Roles-Create',
      VIEW: 'Roles-View',
      EDIT: 'Roles-Edit',
      DELETE: 'Roles-Delete',
    },
    ManageTransaction: {
      CREATE: 'Transaction-Create',
      VIEW: 'Transaction-View',
    },
    RegistrationConsent: { UPDATE: 'Registration-update' },
    PlanSubscription: { CREATE: 'Plan-Create' },
    PlanUpgrade: { UPGRADE: 'Plan-Upgrade' },
    PaymentMethod: { ADD: 'PaymentMethod-Add' },
    InspectionPlan: {
      CREATE: 'InspectionPlan-Create',
      VIEW: 'InspectionPlan-View',
      DELETE: 'InspectionPlan-Delete',
    },
    BuildArea: {
      CREATE: 'BuildArea-Create',
      VIEW: 'BuildArea-View',
      DELETE: 'BuildArea-Delete',
    },
    MaterialMaster: {
      CREATE: 'MaterialMaster-Create',
      VIEW: 'MaterialMaster-View',
      DELETE: 'MaterialMaster-Delete',
    },
    WorkStation: {
      CREATE: 'WorkStation-Create',
      VIEW: 'WorkStation-View',
      DELETE: 'WorkStation-Delete',
    },
    Plants: {
      CREATE: 'Plant-Create',
      VIEW: 'Plant-View',
      DELETE: 'Plant-Delete',
    },
    InspectionLot: {
      CREATE: 'InspectionLot-Create',
      VIEW: 'InspectionLot-View',
      DELETE: 'InspectionLot-Delete',
    },
    GeneralWorkflow: { VIEW: 'Forms-View' },
    ApprovalTrails: { TRAIL: 'Form-Trail', TRAILS_VIEW: 'Form-Trail-View' },
    ApprovalGroups: {
      CREATE: 'ApprovalGroup-Create',
      ADD: 'ApprovalGroup-Add',
      VIEW: 'ApprovalGroup-View',
    },
    User: {
      CREATE: 'User-Create',
      VIEW: 'User-View',
      EDIT: 'User-Edit',
      DELETE: 'User-Delete',
    },
    RoleManagement: {
      CREATE: 'RoleManagement-Create',
      VIEW: 'RoleManagement-View',
      EDIT: 'RoleManagement-Edit',
      DELETE: 'RoleManagement-Delete',
    },
    Workflow: { CREATE: 'Workflow-Ceate', VIEW: 'Workflow-View' },
    EnabledTrails: { TRAIL: 'Enabled-Trail' },
    ApprovalForm: { CREATE: 'Form-Create' },
    RejectionReason: {
      CREATE: 'RejectionReason-Create',
      VIEW: 'RejectionReason-View',
      DELETE: 'RejectionReason-Delete',
      CHILD: 'RejectionReason-Child',
    },
    MaterialNoConfig: { VIEW: 'MaterialNoConfig-View' },
    MeasurementUnits: {
      CREATE: 'MeasurementUnits-Create',
      VIEW: 'MeasurementUnits-View',
      DELETE: 'MeasurementUnits-Delete',
    },
    AdminLogs: { VIEW: 'AdminLogs-View' },
    Groups: {
      CREATE: 'Group-Create',
      VIEW: 'Group-View',
      DELETE: 'Group-Delete',
    },
    Sensors: {
      CREATE: 'Sensor-Create',
      VIEW: 'Sensor-View',
      DELETE: 'Sensor-Delete',
    },
    SensorType: {
      CREATE: 'SensorType-Create',
      VIEW: 'SensorType-View',
      DELETE: 'SensorType-Delete',
    },
    Graphs: { FILTER: 'Graph-Filter' },
    GeneralForm: { VIEW: 'GeneralForm-View' },
    InspectionLotReport: { VIEW: 'InspectionLotReport-View' },
    Dashboard: { VIEW: 'Dashboard-View' },
    Permissions: { EDIT: 'Permission-Edit', VIEW: 'Permission-View' },
    Settings: { VIEW: 'Settings-View' },
    Report: { VIEW: 'Report-View' },
    FMEA: { VIEW: 'FMEA-View' },
    FMEAReport: { VIEW: 'FMEA-Report-View' },
    Report1: { VIEW: 'Report1-View' },
    Report2: { VIEW: 'Report2-View' },
  },
};

export const API = new EAPI({ BaseUrl: key.server.api });
