import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ImTree } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import {
  setReportSubmenu,
  setSettingSubmenu,
} from '../../../Redux/features/AppUtilsSlice';

import '../../../assets/css/main.css';
const LinkItem = ({
  link,
  label,
  icon,
  permission,
  subMenuKey,
  changeStateHandler,
  subExist,
  type,
}) => {
  console.log('subtext', subExist);
  console.log('🚀 ~ file: LinkItem.jsx:11 ~ LinkItem ~ type', type);
  const { permissions } = useSelector((state) => state.Auth);
  const { AppUtils } = useSelector((state) => state);
  const { settingMenu, reportMenu } = AppUtils;

  const dispatch = useDispatch();

  const isSetting = type === 'menu' ? 'menu' : 'reportSubmenu';
  const handleMenu = () => {
    if (type === 'menu') {
      dispatch(setSettingSubmenu(false));
      dispatch(setReportSubmenu(false));
    } else {
    }
  };

  return (
    <>
      {permissions?.includes(permission) ? (
        <NavLink
          onClick={handleMenu}
          activeClassName="active"
          to={link}
          className="menu-link "
        >
          {icon.includes('svg') ? (
            <img
              src={icon}
              className="menu-icon tf-icons svg-icon"
              alt="sidebar menu icon"
            />
          ) : label.includes('Build Area') ? (
            <ImTree className="menu-icon tf-icons " />
          ) : (
            <i className={icon} />
          )}
          <div
            style={{
              display: `flex`,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {label}
            {/* {subExist && <IoIosArrowDown/>} */}
          </div>
        </NavLink>
      ) : null}
    </>
  );
};

export default LinkItem;
