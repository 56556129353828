import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
// import { AiOutlineUser } from 'react-icons/ai';
import { BsPower, BsUnlockFill } from 'react-icons/bs';
import { logoutUser, passwordReset } from '../../../Redux/features/AuthSlice';
// import { key } from "../../../config";
import SoloFormLoading from '../../common/SkeltonLoading/SoloFormLoading';
import PasswordUpdate from './PasswordUpdate';
import Dropdown from 'react-bootstrap/Dropdown';
import { fetchOneCompanyData } from '../../../Redux/features/CompanyManagementSlice';
import { BiUser } from 'react-icons/bi';
const Header = ({ sideBarToggle, setSideBarToggle }) => {
  const navigate = useNavigate();
  const {
    Auth: { Authenticate, currentUser, status },
    CompanyManagement: { company },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [modelOpen, setModelOpen] = React.useState(false);
  const handleOpen = () => setModelOpen(true);
  const handleClose = () => setModelOpen(false);
  // const navigate = useNavigate()
  const logOut = () => {
    dispatch(logoutUser());
    navigate('/login', { state: { authKey: 'log-out' } });
  };

  useEffect(() => {
    const company = localStorage.getItem('companyId');
    if (company) {
      dispatch(fetchOneCompanyData(company));
    }
    // //console.log("company", company);
  }, [dispatch]);

  // navigate('/login')
  return (
    <nav
      className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div className="container-fluid">
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
          <Link
            className="nav-item nav-link px-0 me-xl-4"
            to="#"
            data-bs-toggle="collapse"
            data-bs-target="#layout-menu"
          >
            {sideBarToggle && (
              <i
                className="bx bx-menu bx-sm"
                onClick={() => setSideBarToggle(false)}
              />
            )}
          </Link>
        </div>
        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse"
        >
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            {/* User */}
            <Dropdown>
              <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <Dropdown.Toggle id="dropdown" variant="none">
                  <Link
                    className="nav-link dropdown-toggle hide-arrow"
                    to="#"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="avatar avatar-online">
                      <img
                        src="\assets\img\avatar2.png"
                        alt="avatar"
                        className="rounded-circle"
                      />
                    </div>
                  </Link>
                </Dropdown.Toggle>

                <Dropdown.Menu variant="light">
                  <div>
                    <li>
                      {Authenticate && status !== 'loading' ? (
                        <Link
                          to={`/user/edit/${currentUser?.id}`}
                          className="dropdown-item"
                        >
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div
                                className={`avatar ${
                                  Authenticate
                                    ? ' avatar-online '
                                    : ' avatar-offline '
                                }`}
                              >
                                <img
                                  src="/assets/img/avatar2.png"
                                  alt="avatar"
                                  className="rounded-circle"
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <span className="fw-semibold d-block lh-1">
                                {currentUser?.username}(
                                <small>{currentUser?.role[0]?.name}</small>)
                              </span>
                              <small>{company?.name}</small>
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <SoloFormLoading times={[1, 2, 3]} />
                      )}
                    </li>
                    {currentUser?.company ? (
                      <>
                        <li>
                          <div className="dropdown-divider" />
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to={`/user/edit/${currentUser?.id}`}
                          >
                            <BiUser className="me-2" size={'1.5rem'} />
                            <span className="align-middle">My Profile</span>
                          </Link>
                        </li>
                      </>
                    ) : null}
                    <li>
                      <div className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" to="#">
                        <BsUnlockFill className="me-2" size={'1rem'} />
                        <span
                          onClick={() => {
                            handleOpen();
                          }}
                          className="align-middle"
                        >
                          Password Update
                        </span>
                      </Link>
                    </li>
                    <li>
                      <div className="dropdown-divider" />
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={logOut}>
                        <BsPower size={'1.2rem'} className="me-2" />
                        <span className="align-middle">Log Out</span>
                      </button>
                    </li>
                  </div>
                </Dropdown.Menu>
              </li>
            </Dropdown>
            {/*/ User */}
          </ul>
        </div>
        {/* Search Small Screens */}
        <div className="navbar-search-wrapper search-input-wrapper d-none">
          <input
            type="text"
            className="form-control search-input container-fluid border-0"
            placeholder="Search..."
            aria-label="Search..."
          />
          <i className="bx bx-x bx-sm search-toggler cursor-pointer" />
        </div>
      </div>
      <PasswordUpdate
        handleClose={handleClose}
        passFun={passwordReset}
        open={modelOpen}
      />
    </nav>
  );
};

export default Header;
