import BreadcrumLink from './components/BreadcrumLink';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
const MainBreadcrum = ({
  links,
  name,
  subName,
  permis,
  path,
  label,
  modal,
  nav,
}) => {
  const navigate = useNavigate();
  const { permissions } = useSelector((state) => state.Auth);
  return (
    <div>
      <div className="creat d-flex justify-content-between">
        <h2 className="fw-bold" style={{ fontFamily: 'Rubik, sans-serif' }}>
          {name} <span className="fs-5 p-abs">{subName && `(${subName})`}</span>
        </h2>
        {nav ? (
          <div className="col-4 align-self-center text-end">
            <button
              onClick={() =>
                navigate({
                  pathname: path,
                })
              }
              type="button"
              className="btn btn-primary"
            >
              Create
            </button>
          </div>
        ) : (
          <>
            {permissions.includes(permis) ? (
              <div>
                {modal ? (
                  <div className="col-4 align-self-center text-end">
                    <button
                      onClick={path}
                      type="button"
                      className="btn btn-primary"
                    >
                      {label}
                    </button>
                  </div>
                ) : (
                  <Link to={path}>
                    <button type="button" className="btn btn-primary">
                      {label}
                    </button>
                  </Link>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>

      <div className="res-divider dropdown-divider mb-3" />
      <div className="d-flex">
        {links?.map(({ path, title, activeLink }) => (
          <BreadcrumLink
            key={path}
            path={path}
            title={title}
            activeLink={activeLink}
          />
        ))}
      </div>
    </div>
  );
};

export default MainBreadcrum;
