import React from "react";
import { Route, Routes } from "react-router-dom";
import CompanyPlansListPage from "../pages/companyPlans/CompanyPlansList";
import StaticSiteLayout from "../Layout";

const index = () => {
  return (
    <StaticSiteLayout>
      <Routes>
        <Route path="/register/plans" element={<CompanyPlansListPage />} />
        {/* <Route index element={<Home />} />
        <Route path="teams" element={<Teams />}>
        <Route path=":teamId" element={<Team />} />
        <Route path="new" element={<NewTeamForm />} />
        <Route index element={<LeagueStandings />} />
    </Route> */}
        {/* </Route> */}
      </Routes>
    </StaticSiteLayout>
  );
};

export default index;
