import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../config';
import { SHOW_ERROR } from '../../utils/toastMessages';

const initialState = {
  dashboard: {
    Accepted: '',
    Rejected: '',
    'Partially Accepted': '',
    'Under Review': '',
    Inprogress: '',
    data: {
      pielabel: [],
      pieValue: [],
    },
  },
  QcPointPieData: {},
  steps: [],
  stepsBarData: {},
  QcPoints: [],
  inspectors: [],
  inspectionLot: {
    Accepted: '',
    Rejected: '',
    'Partially Accepted': '',
    'Under Review': '',
    Inprogress: '',
    data: {},
  },
  defectInspectionPlan: [],
  status: null,
  error: null,
};

export const addHistogramQualitativedata = createAsyncThunk(
  'post/qualitativeData',
  async (graphData) => {
    try {
      // //console.log("id", graphData.qc);
      if (
        graphData.qc.filter((u) => u.id !== graphData.id)[0].isMeasurement === 0
      ) {
        const response = await API.graphs.histogram.qualitative(graphData.data);
        const { data, success } = response.data;
        return {
          success,
          data,
          code: 200,
        };
      } else {
        const response = await API.graphs.histogram.quantitative(
          graphData.data
        );
        const { data, success } = response.data;
        //sort data
        // data.sort((a, b) => a.x - b.x);
        const sorting = data.values.sort((a, b) => a.value - b.value);
        // //console.log("sorting", sorting);
        const totalTitle = sorting
          .filter((f) => f.value !== null)
          .map((tt) => tt.value);
        const totalCount = data.values
          .filter((f) => f.count !== 0)
          .map((tt) => tt.count);
        return {
          success,
          data: {
            titles: totalTitle,
            counts: totalCount,
            data,
          },
          code: 200,
        };
      }
      // //console.log(success, data);
    } catch (error) {
      if (error) {
        // //console.log("error", error);
        return [];
      }
    }
  }
);

export const getSteps = createAsyncThunk('getAllSteps', async (id) => {
  try {
    const response = await API.graphs.steps(id);

    return response.data.data;
  } catch (error) {
    if (error) {
      return [];
    }
  }
});

export const getQcPoints = createAsyncThunk('getAllQcPoints', async (data) => {
  try {
    // //console.log(data);
    const response = await API.graphs.qcPoints(data.id, data.qty);
    return response.data.data;
  } catch (error) {
    // // //console.log(error.response.data.message);
    if (error) {
      return [];
    }
  }
});

export const getInspectors = createAsyncThunk(
  'get/histogramInspector',
  async () => {
    try {
      const response = await API.graphs.inspectors();
      // // //console.log(response.data);
      return response.data.data;
    } catch (error) {
      // // //console.log(error.response.data.message);
      if (error) {
        return [];
        // toast.error("Something went wrong!", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        // });
      }
    }
  }
);

export const addStepData = createAsyncThunk(
  'post/stepData',
  async (formData) => {
    try {
      const response = await API.graphs.stepData(formData);
      // //console.log(response.data);
      const { data, success, message } = response.data;
      return {
        success,
        message,
        data,
        code: 200,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, errors.code[0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const addInspectionLotData = createAsyncThunk(
  'post/inspectionLotData',
  async (formData) => {
    try {
      const response = await API.graphs.inspectionLotGraph(formData);
      // //console.log(response.data);
      const { success, message } = response.data;
      return {
        success,
        message,
        code: 200,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, errors.code[0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const addDefectInspectionPlanData = createAsyncThunk(
  'post/defectInspectionPlanData',
  async (formData) => {
    try {
      const response = await API.graphs.defectInspectionPlan(formData);
      // //console.log(response.data.data);
      const { success, message, data } = response.data;
      if (data.length === undefined) {
        // //console.log("arr", data);
        const totalTitle = Object.values(data).map((tt) => tt.inspectionTitle);
        const totalrejectedQty = Object.values(data).map(
          (tt) => {
            // //console.log("tities :", tt.rejectedQty);
            return tt.rejectedQty;
          }
          // Math.floor(Math.random() * 100)
        );

        return {
          success,
          data: {
            titles: totalTitle,
            rejectedQtys: totalrejectedQty,
            comData: data,
          },
          code: 200,
        };
      } else {
        // //console.log("data", data);
        const totalTitle = data.map((tt) => tt.inspectionTitle);
        const totalrejectedQty = data.map(
          (tt) => {
            // //console.log("tities :", tt.rejectedQty);
            return tt.rejectedQty;
          }
          // Math.floor(Math.random() * 100)
        );

        return {
          success,
          data: {
            titles: totalTitle,
            rejectedQtys: totalrejectedQty,
            comData: data,
          },
          code: 200,
        };
      }
    } catch (error) {
      // //console.log(error);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const getInsoectionLotData = createAsyncThunk(
  'graph/inspectorLot',
  async (formData) => {
    try {
      const response = await API.graphs.inspectionLot(formData);
      const { message, data, success } = response.data;

      // //console.log("data", response.data);

      let pielabel = Object.keys(data);
      const pieValue = pielabel.map((PV) => data[PV]);

      return {
        success,
        data: { pielabel, pieValue, pieData: data },
        code: 200,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const getDashboardData = createAsyncThunk(
  'get/dashboardData',
  async (date) => {
    try {
      const res = await API.graphs.dashboard(date);

      const { data, success, message } = res.data;
      let pielabel = data.InspectionLots.map((u) => u.usageDecision);
      const pieValue = data.InspectionLots.map((u) => u.count);
      return {
        success,
        data: { pielabel, pieValue, pieData: data },
        code: 200,
      };
    } catch (error) {
      // //console.log("dashboard data error => ", error);
      return error;
    }
  }
);

const GraphsSlice = createSlice({
  name: 'graphs',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addHistogramQualitativedata.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addHistogramQualitativedata.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = 'succeeded';
          state.QcPointPieData = {
            failCount: data?.failCount,
            passCount: data?.passCount,
            pieData: data,
          };
        } else {
          state.status = 'failed';
          state.error = code;
        }
      })
      .addCase(addHistogramQualitativedata.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      .addCase(getSteps.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getSteps.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.steps = action.payload;
      })
      .addCase(getSteps.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      .addCase(getQcPoints.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getQcPoints.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.QcPoints = action.payload;
      })
      .addCase(getQcPoints.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getInspectors.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getInspectors.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.inspectors = action.payload;
      })
      .addCase(getInspectors.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      .addCase(getInsoectionLotData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getInsoectionLotData.fulfilled, (state, action) => {
        const {
          success,
          data: { pielabel, pieValue, pieData },
          code,
        } = action.payload;
        if (success && code === 200) {
          state.status = 'succeeded';
          state.inspectionLot.data = { pielabel, pieValue, pieData };
          Object.keys(state.inspectionLot).forEach((label) => {
            if (pielabel.includes(label)) {
              if (label !== 'data') {
                state.inspectionLot[label] = pieValue[pielabel.indexOf(label)];
              }
            } else {
              console.log('not found', label);
              if (label !== 'data') {
                state.inspectionLot[label] = 0;
              }
            }
          });
        } else {
          state.status = 'failed';
          state.error = code;
        }
      })
      .addCase(getInsoectionLotData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //dashboard
      .addCase(getDashboardData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        const {
          success,
          data: { pielabel, pieValue, pieData },
          code,
        } = action.payload;
        if (success && code === 200) {
          state.status = 'succeeded';
          state.dashboard.data = { pielabel, pieValue, pieData };
          Object.keys(state.dashboard).forEach((label) => {
            if (pielabel.includes(label)) {
              if (label !== 'data') {
                state.dashboard[label] = pieValue[pielabel.indexOf(label)];
              }
            } else {
              console.log('not found', label);
              if (label !== 'data') {
                state.dashboard[label] = 0;
              }
            }
          });
        } else {
          state.status = 'failed';
          // //console.log("code", code);
          state.error = code;
        }
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.status = 'failed';
      })

      // steps bar data
      .addCase(addStepData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addStepData.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = 'succeeded';
          state.stepsBarData = {
            bar: data,
          };
        } else {
          state.status = 'failed';
          state.error = code;
        }
      })
      .addCase(addStepData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // defect
      .addCase(addDefectInspectionPlanData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addDefectInspectionPlanData.fulfilled, (state, action) => {
        const {
          success,
          data: { rejectedQtys, titles, comData },
          code,
        } = action.payload;
        if (success && code === 200) {
          state.status = 'succeeded';
          state.defectInspectionPlan = {
            rejectedQtys,
            titles,
            comData,
          };
        } else {
          state.status = 'failed';
          state.error = code;
        }
      })
      .addCase(addDefectInspectionPlanData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default GraphsSlice.reducer;
