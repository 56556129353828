import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../config';
import { toast } from 'react-toastify';
import { SHOW_ERROR, SHOW_SUCCESS } from '../../utils/toastMessages';
import { ERROR_MESS, USER_CREATE } from '../../constant';

const initialState = {
  users: [],
  user: {},
  error: null,
  status: null,
};

export const getAllUsers = createAsyncThunk('get/users', async (page) => {
  try {
    const response = await API.Users.getAll({
      key: page ? 'page' : '',
      value: page,
    });

    const { success, data } = response.data;

    return { success, data, code: 200 };
  } catch (error) {
    const { code, success, message, errors } = error.response.data;
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 422) {
      SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
    } else {
      SHOW_ERROR(true, ERROR_MESS);
    }
    return {
      success,
      data: {},
      code,
    };
  }
});

export const addUsersData = createAsyncThunk('post/users', async (formdata) => {
  try {
    const response = await API.Users.add(formdata.values);
    // // // //console.log(response.data);
    const { success, data } = response.data;
    if (success) {
      SHOW_SUCCESS(true, 'User created successfully');
      formdata.resetForm();
    }
    // //console.log(response.data);

    return {
      success,
      data,
      code: 200,
    };
  } catch (error) {
    const { code, success, message, errors } = error.response.data;
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 422) {
      SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
    } else {
      SHOW_ERROR(true, ERROR_MESS);
    }
    return {
      success,
      data: {},
      code,
    };
  }
});

export const updateOneUserData = createAsyncThunk(
  'update/user',
  async (formData) => {
    try {
      // // // //console.log("from slice :", data);
      const response = await API.Users.update(formData.id, formData.finalData);
      const { success, message, data } = response.data;
      if (success) {
        SHOW_SUCCESS(success, message);
        formData.setEditToggle(true);
      }

      return {
        success,
        data,
        code: 200,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const updatePassword = createAsyncThunk(
  'update/password',
  async (data) => {
    try {
      // // // //console.log("from slice :", data);
      const response = await API.Users.passwordUpdate(data);
      if (response.data.success) {
        toast.success('Updated Successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      return response.data;
    } catch (error) {
      // // // //console.log("FROM SLICE API ERROR", error);
      const { code, success, message, errors } = error.response.data;

      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (
        !success &&
        code === 422 &&
        errors.hasOwnProperty('password')
      ) {
        SHOW_ERROR(true, errors.password[0]);
      } else {
        SHOW_ERROR(true, message);
      }
      return error.response;
    }
  }
);

export const deleteUser = createAsyncThunk('delete/user', async (data) => {
  try {
    const response = await API.Users.delete(data);
    // // //console.log(response.data);
    SHOW_SUCCESS(true, 'User deleted successfully');

    return data;
  } catch (error) {
    // // //console.log(error.response.data.message);
    const { code, success, message } = error.response.data;
    // console.log("error => ", error.response.data);
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 404) {
      SHOW_ERROR(true, message);
    } else {
      SHOW_ERROR(true, ERROR_MESS);
    }
    return error.response.data;
  }
});

export const fetchOneUserData = createAsyncThunk('get/user', async (id) => {
  try {
    const response = await API.Users.getOne(id);
    // // //console.log(response.data);
    return response.data.data;
  } catch (error) {
    // // //console.log(error.response.data.message);
    return error.response.data;
  }
});

// export const addNewPost = createAsyncThunk('posts/addNewPost', async (initialPost) => {
//   const response = await axios.post(POSTS_URL, initialPost)
//   return response.data
// })

const UsersSlice = createSlice({
  name: 'Users',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllUsers.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        const { code, data, success } = action.payload;

        if (success && code === 200) {
          state.status = 'succeeded';
          state.users = data;
        } else {
          state.status = 'failed';
        }
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      // post data reduces
      .addCase(addUsersData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addUsersData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.success) {
          state.users.push(action.payload.data);
        }
        // Adding date and reactions

        // Add any fetched posts to the array
        // // // //console.log(action.payload);
        // state.plans = action.payload.inspectionPlans;
      })
      .addCase(addUsersData.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      //password update
      .addCase(updatePassword.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.status = 'rejected';
      })

      // get plan data reduces
      .addCase(fetchOneUserData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchOneUserData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        let newUser = { ...action.payload, role: action?.payload?.role[0]?.id };
        state.user = newUser;
      })
      .addCase(fetchOneUserData.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      // delete plan data reduces
      .addCase(deleteUser.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Adding date and reactions
        state.users = state.users.filter((item) => item.id !== action.payload);
        // Add any fetched posts to the array
        // // // //console.log(action.payload);
        // state.plans = action.payload.inspectionPlans;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      });
  },
});

export default UsersSlice.reducer;
