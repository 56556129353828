import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { API } from '../../config';
import { SHOW_ERROR } from '../../utils/toastMessages';

const initialState = {
  generalForms: {
    workflowForms: [],
  },
  generalFormFilledBy: [],
  generalFormDetail: {},
  error: null,
  status: null,
  paginationData: null,
};
export const getAllGeneralForm = createAsyncThunk(
  'get/generalForm',
  async () => {
    try {
      const response = await API.generalForm.getAllForms();
      // // //console.log(response.data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getFormsFilledBy = createAsyncThunk(
  'get/formsFilledBy',
  async (id) => {
    try {
      const response = await API.generalForm.getAllFormsFilledBy(id);
      const { success, message, data } = response.data;

      return {
        success,
        data,
        code: 200,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, errors.code[0]);
      } else {
        SHOW_ERROR(true, 'Something Went Wrong !');
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const getFormDetails = createAsyncThunk(
  'get/formDetails',
  async (id) => {
    try {
      const response = await API.generalForm.getFormDetail(id);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const GeneralFormSlice = createSlice({
  name: 'General Form',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllGeneralForm.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getAllGeneralForm.fulfilled, (state, action) => {
        state.generalForms = action.payload.data;
        state.status = 'succeeded';
        delete action.payload.forms;
        state.paginationData = action.payload.data;
      })
      .addCase(getAllGeneralForm.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getFormsFilledBy.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getFormsFilledBy.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          state.status = 'succeeded';
          state.generalFormFilledBy = data;
        } else {
          state.status = 'failed';
          state.error = code;
        }
      })
      .addCase(getFormsFilledBy.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getFormDetails.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getFormDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.generalFormDetail = action.payload.data;
      })
      .addCase(getFormDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});
export default GeneralFormSlice.reducer;
