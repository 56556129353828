import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../config';
import { toast } from 'react-toastify';
import { SHOW_ERROR, SHOW_SUCCESS } from '../../utils/toastMessages';
import {
  ERROR_MESS,
  PLANT_CREATE,
  PLANT_DELETE,
  PLANT_UPDATE,
} from '../../constant';

const initialState = {
  plants: [],
  plant: {},
  error: null,
  status: null,
  isSubmitted: null,
};

export const fetchPlants = createAsyncThunk('get/allPlants', async (page) => {
  try {
    const response = await API.plant.getAll({
      key: page ? 'page' : '',
      value: page,
    });
    // // //console.log(response.data);
    return { page, data: response.data.data };
  } catch (error) {
    // // //console.log(error.response.data.message);
    return error.response.data;
  }
});

export const addPlantData = createAsyncThunk(
  'post/plant',
  async (submitData) => {
    try {
      const response = await API.plant.add(submitData.values);
      // //console.log(response.data);
      // const { code, success, message } = error.response.data;
      const { success, data } = response.data;
      if (success) {
        SHOW_SUCCESS(success, 'Plant created successfully');
        submitData.resetForm();
      }
      // //console.log(response.data);

      return {
        success,
        data,
        code: 201,
      };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message.error);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const updateOnePlant = createAsyncThunk('update/plant', async (data) => {
  try {
    // // // //console.log("from slice :", data);
    const response = await API.plant.update(data.id, data.finalData);
    if (response.data.success) {
      SHOW_SUCCESS(response.data.success, PLANT_UPDATE);
      data.navigate('/plant');
    }
    return response.data.data;
  } catch (error) {
    const { code, success, message, errors } = error.response.data;
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 422) {
      SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
    } else {
      SHOW_ERROR(true, ERROR_MESS);
    }
    return {
      success,
      data: {},
      code,
    };
  }
});

export const deletePlant = createAsyncThunk('delete/plant', async (data) => {
  try {
    const response = await API.plant.delete(data);
    if (response.data.success) {
      SHOW_SUCCESS(response.data.success, PLANT_DELETE);
    }
    return data;
  } catch (error) {
    // // //console.log(error.response.data.message);
    const { code, success, message, errors } = error.response.data;
    // console.log("error => ", error.response.data);
    if (!success && code === 400) {
      SHOW_ERROR(true, message);
    } else if (!success && code === 404) {
      SHOW_ERROR(true, message);
    } else {
      SHOW_ERROR(true, ERROR_MESS);
    }
    return error.response.data;
  }
});

export const fetchOnePlantData = createAsyncThunk('get/plant', async (id) => {
  try {
    const response = await API.plant.getOne(id);
    // // //console.log(response.data);
    return response.data.data;
  } catch (error) {
    // // //console.log(error.response.data.message);
    return error.response.data;
  }
});

const plantSlice = createSlice({
  name: 'plant',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlants.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchPlants.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { page, data } = action.payload;

        if (page) {
          state.plants = data.plants;
          // // // //console.log(action.payload.materialMasters);
          delete data.plants;
          state.paginationData = data;
        } else {
          state.plants = data;
        }
      })
      .addCase(fetchPlants.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(fetchOnePlantData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchOnePlantData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.plant = action.payload;
      })
      .addCase(fetchOnePlantData.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(deletePlant.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(deletePlant.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.plants = state.plants.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deletePlant.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })
      .addCase(addPlantData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(addPlantData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const { success, data, code } = action.payload;
        if (success) {
          state.plants.unshift(data);
          state.isSubmitted = success;
          state.error = code;
        } else {
          state.error = code;
          state.isSubmitted = success;
        }
      })
      .addCase(addPlantData.rejected, (state, action) => {
        state.status = 'failed';
        // // // //console.log(action.error);
        state.error = action.payload;
      })

      .addCase(updateOnePlant.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateOnePlant.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(updateOnePlant.rejected, (state, action) => {
        state.status = 'rejected';
      });
  },
});

// export const { postAdded, reactionAdded } = plantSlice.actions;

export default plantSlice.reducer;
