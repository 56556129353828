// **************************** ERROR ****************************
export const ERROR_MESS = "Something went wrong!";

// **************************** COMPANY ****************************
export const COMPANY_CREATE = "Company created successfully.";
export const COMPANY_DELETE = "Company deleted successfully.";
export const COMPANY_UPDATE = "Company updated successfully.";

// **************************** PLANS ****************************
export const PLANS_CREATE = "Plan created successfully.";
export const PLANS_DELETE = "Plan deleted successfully.";
export const PLANS_UPDATE = "Plan updated successfully.";

// **************************** ROLES ****************************
export const ROLE_CREATE = "Role created successfully.";
export const ROLE_DELETE = "Role deleted successfully.";
export const ROLE_UPDATE = "Role updated successfully.";

// **************************** REGISTRATION ****************************
export const REGISTRATION_CREATE = "Registration Consent created successfully.";
export const REGISTRATION_DELETE = "Registration Consent deleted successfully.";
export const REGISTRATION_UPDATE = "Registration Consent updated successfully.";

// **************************** INSPECTION PLAN ****************************
export const INSPECTION_PLAN_CREATE = "Inspection Plan created successfully.";
export const INSPECTION_PLAN_DELETE = "Inspection Plan deleted successfully.";
export const INSPECTION_PLAN_UPDATE = "Inspection Plan updated successfully.";

// **************************** BUILD AREA ****************************
export const BUILD_AREA_CREATE = "Build Area created successfully.";
export const BUILD_AREA_DELETE = "Build Area deleted successfully.";
export const BUILD_AREA_UPDATE = "Build Area updated successfully.";

// **************************** MATERIAL MASTER ****************************
export const MATERIAL_MASTER_CREATE = "Material Master created successfully.";
export const MATERIAL_MASTER_DELETE = "Material Master deleted successfully.";
export const MATERIAL_MASTER_UPDATE = "Material Master updated successfully.";

// **************************** WORK STATION ****************************
export const WORK_STATION_CREATE = "Work Station created successfully.";
export const WORK_STATION_DELETE = "Work Station deleted successfully.";
export const WORK_STATION_UPDATE = "Work Station updated successfully.";

// **************************** PLANT ****************************
export const PLANT_CREATE = "Plant created successfully.";
export const PLANT_DELETE = "Plant deleted successfully.";
export const PLANT_UPDATE = "Plant updated successfully.";

// **************************** INSPECTION LOT ****************************
export const INSPECTION_LOT_CREATE = "Inspection Lot created successfully.";
export const INSPECTION_LOT_DELETE = "Inspection Lot deleted successfully.";
export const INSPECTION_LOT_UPDATE = "Inspection Lot updated successfully.";

// **************************** WORKFLOW FORM BUILDER ****************************
export const WORKFLOW_FORM_BUILDER_CREATE = "Form created successfully.";
export const WORKFLOW_FORM_BUILDER_DELETE = "Form deleted successfully.";
export const WORKFLOW_FORM_BUILDER_UPDATE = "Form updated successfully.";

// **************************** ROLE MANAGEMENT ****************************
export const ROLE_MANAGEMENT_CREATE = "Role Management created successfully.";
export const ROLE_MANAGEMENT_DELETE = "Role Management deleted successfully.";
export const ROLE_MANAGEMENT_UPDATE = "Role Management updated successfully.";

// **************************** REJECTION REASON ****************************
export const REJECTION_REASON_CREATE = "Rejection Reason created successfully.";
export const REJECTION_REASON_DELETE = "Rejection Reason deleted successfully.";
export const REJECTION_REASON_UPDATE = "Rejection Reason updated successfully.";

// **************************** MATERIAL NO CONFIG ****************************
export const MATERIAL_NO_CONFIG_CREATE =
  "Material No Config created successfully.";
export const MATERIAL_NO_CONFIG_DELETE =
  "Material No Config deleted successfully.";
export const MATERIAL_NO_CONFIG_UPDATE =
  "Material No Config updated successfully.";

// **************************** MEASUREMENT UNIT ****************************
export const MEASUREMENT_UNIT_CREATE = "Measurement Unit created successfully.";
export const MEASUREMENT_UNIT_DELETE = "Measurement Unit deleted successfully.";
export const MEASUREMENT_UNIT_UPDATE = "Measurement Unit updated successfully.";

// **************************** GROUPS ****************************
export const GROUP_CREATE = "Group created successfully.";
export const GROUP_DELETE = "Group deleted successfully.";
export const GROUP_UPDATE = "Group updated successfully.";

// **************************** SENSORS ****************************
export const SENSOR_CREATE = "Sensor created successfully.";
export const SENSOR_DELETE = "Sensor deleted successfully.";
export const SENSOR_UPDATE = "Sensor updated successfully.";

// **************************** SENSOR TYPE ****************************
export const SENSOR_TYPE_CREATE = "Sensor Type created successfully.";
export const SENSOR_TYPE_DELETE = "Sensor Type deleted successfully.";
export const SENSOR_TYPE_UPDATE = "Sensor Type updated successfully.";

// **************************** FIXED FIELD FORM ****************************
export const FIXED_FORM_FIELD_CREATE = "Approval Form created successfully.";
export const FIXED_FORM_FIELD_DELETE = "Approval Form deleted successfully.";
export const FIXED_FORM_FIELD_UPDATE = "Approval Form updated successfully.";

// **************************** User ****************************
export const USER_CREATE = "User created successfully.";
