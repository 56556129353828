import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useTable,
  useSortBy,
  useFilters,
  useColumnOrder,
  usePagination,
  useAsyncDebounce,
  useGlobalFilter,
} from 'react-table';
import { motion, AnimatePresence } from 'framer-motion';
import { matchSorter } from 'match-sorter';
import { IoCaretForwardOutline } from 'react-icons/io5';
import { IoCaretBackOutline } from 'react-icons/io5';
// import { IoCaretBackwardOutline } from "react-icons/io";
import { FaStepBackward } from 'react-icons/fa';
import { FaStepForward } from 'react-icons/fa';
import { HiFastForward } from 'react-icons/hi';
import BTable from 'react-bootstrap/Table';

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows?.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="row mb-2">
      <div className="col-md-8 col-sm-6 col-1"></div>
      <div className="col-md-4 col-sm-6 col-11">
        <div className="input-group rounded">
          <input
            type="search"
            className="form-control rounded"
            value={value || ''}
            onChange={(e) => {
              console.log('Trigerred');
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`${count} records...`}
          />
          <span className="input-group-text border-0" id="search-addon">
            <i className="fas fa-search"></i>
          </span>
        </div>
      </div>
    </div>
  );
}

// Define a default UI for filtering

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="form-control"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// function shuffle(arr) {
//   arr = [...arr];
//   const shuffled = [];
//   while (arr.length) {
//     const rand = Math.floor(Math.random() * arr.length);
//     shuffled.push(arr.splice(rand, 1)[0]);
//   }
//   return shuffled;
// }

function Table({ lotReport, columns, data, path, permis }) {
  const navigate = useNavigate();
  const { permissions } = useSelector((state) => state.Auth);
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // const tableHooks = (hooks) => {
  //   hooks.visibleColumns.push((columns) => [
  //     ...columns,
  //     {
  //       id: "Action",
  //       Header: "Action",
  //       Cell: ({ row }) => (
  //         <button onClick={() => alert("Editing: " + row.values.id)}>
  //           Edit
  //         </button>
  //       ),
  //     },
  //   ]);
  // };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    // rows,
    // visibleColumns,
    prepareRow,
    // setColumnOrder,
    state: { pageIndex, pageSize, globalFilter },
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useColumnOrder,
    useFilters,
    // tableHooks,
    useSortBy,
    usePagination
  );

  const spring = React.useMemo(
    () => ({
      type: 'spring',
      damping: 50,
      stiffness: 100,
    }),
    []
  );
  //   console.log("row data => ", page);

  //   const randomizeColumns = () => {
  //     setColumnOrder(shuffle(visibleColumns.map((d) => d.id)));
  //   };

  return (
    <>
      {/* <button onClick={() => randomizeColumns({})}>Randomize Columns</button> */}
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
      />
      <div className="table-responsive text-nowrap">
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <motion.th
                    className={
                      column.Header === 'Action' ? 'text-center pe-5' : null
                    }
                    {...column.getHeaderProps({
                      layoutTransition: spring,
                      style: {
                        minWidth: column.minWidth,
                      },
                    })}
                  >
                    <div {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </div>
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </motion.th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="table-border-bottom-0" {...getTableBodyProps()}>
            <AnimatePresence>
              {page.slice(0, 10).map((row, i) => {
                prepareRow(row);
                return (
                  <motion.tr
                    style={{ height: '40px' }}
                    {...row.getRowProps({
                      layoutTransition: spring,
                      exit: { opacity: 0, maxHeight: 0 },
                    })}
                  >
                    {row.cells.map((cell, i) => {
                      return (
                        <motion.td
                          onClick={() => {
                            if (!lotReport) {
                              if (cell.column.Header === 'Action') {
                                return null;
                              } else {
                                return permissions.includes(permis)
                                  ? navigate(path + `${row.original.id}`)
                                  : null;
                              }
                            } else {
                              return lotReport
                                ? navigate(
                                    path +
                                      `${row.original.id}/${row?.original?.eventFlag}`
                                  )
                                : null;
                            }
                          }}
                          {...cell.getCellProps({
                            layoutTransition: spring,
                          })}
                        >
                          {cell.render('Cell')}
                        </motion.td>
                      );
                    })}
                  </motion.tr>
                );
              })}
            </AnimatePresence>
          </tbody>
        </table>
      </div>
      <div
        style={{ overflow: 'auto', whiteSpace: 'nowrap' }}
        className="pagination float-end my-3 h-25"
      >
        <div className="d-inline me-md-5 me-3 my-auto border p-2 rounded border-secondary">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </div>
        <div className="d-inline d-sm-block d-none">
          <label htmlFor="goto">Go to page: </label>
          <input
            id="goto"
            className="ms-1 me-md-2 form-control d-inline"
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '50px' }}
          />
        </div>{' '}
        <label className="my-auto d-sm-block d-none" htmlFor="RP">
          Rows per page:{' '}
        </label>
        <select
          style={{ width: '70px' }}
          className="ms-1 me-md-2 form-control form-select d-sm-block d-none"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        <button
          className="form-control p-2 btn-sm btn"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <FaStepBackward />
        </button>{' '}
        <button
          className="form-control p-2 btn-sm btn"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <IoCaretBackOutline size={'1.1rem'} />
        </button>{' '}
        <button
          className="form-control p-2 btn-sm btn"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <IoCaretForwardOutline size={'1.1rem'} />
        </button>{' '}
        <button
          className="form-control p-2 btn-sm btn"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <FaStepForward />
        </button>{' '}
      </div>
      {/* <pre>
        <code>{JSON.stringify(state, null, 2)}</code>
      </pre> */}
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

function DataTable({ headings, data, lotReport, path, permis }) {
  //   const results = objects.filter(object => Object.values(object).some(i => i.includes(keyword)));
  const columns = React.useMemo(() => headings, [headings]);

  const DATA = React.useMemo(() => data, [data]);
  // important to remember

  return (
    <>
      <Table
        lotReport={lotReport}
        columns={columns}
        path={path}
        permis={permis}
        data={DATA}
      />
    </>
  );
}

export default DataTable;
