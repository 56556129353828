import { Button, Container, Navbar, Nav } from "react-bootstrap";

function OffcanvasExample() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/register/plans">
          <span className="app-brand-link">
            <span className="app-brand-logo demo">
              <img
                // style={{ filter: "invert(35%)" }}
                height={"34px"}
                src="\assets\img\processintel.png"
                alt="Logo"
              />
            </span>
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/register/plans">Plans</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link eventKey={2} href="/login">
              Log In
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default OffcanvasExample;
